export default function PrivacySurvivorGame(props){

    const title = "Survivor Game Privacy Policy";
    const welcomeMessage = "";
    
    const contentArray = [
        {
            heading:"User Data",
            text:"Currently not gathering any user data.  In the future, may collect user IDs.  "
        },
        
        {
            heading:"Advertisements",
            text:"Currently no ads.  Subject to change."
        },
        
        {
            heading:"App info and performance",
            text:"Crash logs, Diagnostices and Other app performance data."
        },
       
    ]
    return (
        <div>
            <h2>{title}</h2>
            <div>{welcomeMessage}</div>
            {contentArray.map(obj=>{return (
                <>
                <h3>{obj.heading}</h3>
                <div>{obj.text}</div>
                </>
            )})}

        </div>
    );
}