import { motion } from "framer-motion";

import animations from "./animations";

const AnimateItem = ({type, children, tag }) => {
    
    let animationObj = animations['fade']; // default
    if(animations[type]!==undefined){
        animationObj = animations[type];
    }
    if(tag==='tr'){   
        return (
            
            <motion.tr
            variants={animationObj}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.8 }}
            >
            {children}
            </motion.tr>
            
        );
    }
        
  return (
    
    <motion.div
      variants={animationObj}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={{ duration: 0.8 }}
    >
      {children}
    </motion.div>
    
  );
};

export default AnimateItem;
