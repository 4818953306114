const colorless = {
  'Strike':{
    text: 'Strike',
    cats:{
      cost:1,
      type: 'Attack',
      value: 6,
      rarity: 'Basic',
      class: 'Colorless',
      exhaust:false,
    },
    cardText:'Deal 6 (9) damage.'
  },
  'Defend':{
    text: 'Defend',
    cats:{
      cost:1,
      type: 'Skill',
      value: 5,
      rarity: 'Basic',
      class: 'Colorless',
      exhaust:false,
    },
    cardText:'Gain 5 (8) Block.'
  },

  'Bandage Up':{
    text:'Bandage Up',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:4,
      class:'Colorless',
      exhaust:true,
    },
    cardText:	'Heal 4 (6) HP. Exhaust.'
  },
  'Blind':{
    text:'Blind',
    cats:{	
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:2,
    class:'Colorless',
    
    },
    cardText:	'Apply 2 Weak.'
  },
  'Dark Shackles':{
    text:'Dark Shackles',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:9,
      class:'Colorless',
      exhaust:true,
    },
    cardText:	'Enemy loses 9 (15) Strength this turn. Exhaust.'
  },
  'Deep Breath':{
    text:'Dark Breath',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:1,
      class:'Colorless',
    },
    cardText:	'Shuffle your discard pile into your draw pile. Draw 1 card.'
  },
  'Discovery':{
    text:'Discovery',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:1,
      value:1,
      class:'Colorless',
      exhaust:true,
    },
    cardText:	'Choose 1 of 3 random cards to add into your hand. It costs 0 this turn. Exhaust.'
  },
  'Dramatic Entrance':{
    text:'Dramatic Entrance',
    cats:{
      type:'Attack',
      rarity:'Uncommon',
      cost:0,
      value:8,
      class:'Colorless',
      exhaust:true,
    },
    cardText:	'Innate. Deal 8 (12) damage to ALL enemies. Exhaust.'
  },
  'Enlightenment':{
    text:'Enlightenment',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:1,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Reduce the cost of all cards in your hand to 1 this turn.'
},
  'Finesse':{
    text:'Finesse',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:2,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Gain 2 (4) Block. Draw 1 card.'
},
  'Flash of Steel':{
    text:'Flash of Steel',
    cats:{
      type:'Attack',
      rarity:'Uncommon',
      cost:0,
      value:3,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Deal 3 (6) damage. Draw 1 card.'
},
  'Forethought':{
    text:'Forethought',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:0,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Put a card from your hand to the bottom of your draw pile. It costs 0 until played.'
},
  'Good Instincts':{
    text:'Good Instincts',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:6,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Gain 6 (9) Block.'
},
  'Impatience':{
    text:'Impatience',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:2,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'If you have no Attacks in your hand, draw 2 (3) cards.'
},
  'Jack of All Trades':{
    text:'Jack of All Trades',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:1,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Add 1 random Colorless card into your hand. Exhaust.'
},
  'Madness':{
    text:'Madness',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:1,
      value:0,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Reduce the cost of a random card in your hand to 0 this combat. Exhaust.'
},
  'Mind Blast':{
    text:'Mind Blast',
    cats:{
      type:'Attack',
      rarity:'Uncommon',
      cost:2,
      value:0,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Innate. Deal damage equal to the number of cards in your draw pile.'
},
  'Panacea':{
    text:'Panacea',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:1,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Gain 1 (2) Artifact. Exhaust.'
},
  'Panic Button':{
    text:'Panic Button',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:30,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Gain 30 (40) Block. You cannot gain Block from cards for 2 turns. Exhaust.'
},
  'Purity':{
    text:'Purity',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:3,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Exhaust up to 3 (5) cards in your hand. Exhaust.'
},
  'Swift Strike':{
    text:'Swift Strike',
    cats:{
      type:'Attack',
      rarity:'Uncommon',
      cost:0,
      value:7,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Deal 7 (10) damage.'
},
  'Trip':{
    text:'Trip',
    cats:{
      type:'Skill',
      rarity:'Uncommon',
      cost:0,
      value:2,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Apply 2 Vulnerable.'
},
  'Apotheosis':{
    text:'Apotheosis',
    cats:{
      type:'Skill',
      rarity:'Rare',
      cost:2,
      value:0,
      class:'Colorless',
      exhaust:true,
},
  cardText:'Upgrade ALL your cards for the rest of combat. Exhaust.'
},
  'Chrysalis':{
    text:'Chrysalis',
    cats:{
      type:'Skill',
      rarity:'Rare',
      cost:2,
      value:3,
      class:'Colorless',
      exhaust:true,
},
  cardText:'Shuffle 3 (5) random Skills into your draw pile. They cost 0 this combat. Exhaust.'
},
  'Hand of Greed':{
    text:'Hand of Greed',
    cats:{
      type:'Attack',
      rarity:'Rare',
      cost:2,
      value:20,
      class:'Colorless',
      exhaust:false,
},
  cardText:'Deal 20 (25) damage. If Fatal, gain 20 (25) Gold.'
},
  'Magnetism':{
    text:'Magnetism',
    cats:{
      type:'Power',
      rarity:'Rare',
      cost:2,
      value:0,
      class:'Colorless',
      exhaust:false,
},
  cardText:'At the start of your turn, add a random Colorless card into your hand.'
},
  'Master of Strategy':{
    text:'Master of Strategy',
    cats:{
      type:'Skill',
      rarity:'Rare',
      cost:0,
      value:3,
      class:'Colorless',
      exhaust:true,
},
  cardText:'Draw 3 (4) cards. Exhaust.'
},
  'Mayhem':{
    text:'Mayhem',
    cats:{
      type:'Power',
      rarity:'Rare',
      cost:2,
      value:0,
      class:'Colorless',
      exhaust:false,
},
  cardText:'At the start of your turn, play the top card of your draw pile.'
},
  'Metamorphosis':{
    text:'Metamorphosis',
    cats:{
      type:'Skill',
      rarity:'Rare',
      cost:2,
      value:3,
      class:'Colorless',
      exhaust:true,
},
  cardText:'Shuffle 3 (5) random Attacks into your draw pile. They cost 0 this combat. Exhaust.'
},
  'Panache':{
    text:'Panache',
    cats:{
      type:'Power',
      rarity:'Rare',
      cost:0,
      value:5,
      class:'Colorless',
      exhaust:false,
},
  cardText:'Every time you play 5 cards in a single turn, deal 10 (14) damage to ALL enemies.'
},
  'Sadistic Nature':{
    text:'Sadistic Nature',
    cats:{
      type:'Power',
      rarity:'Rare',
      cost:0,
      value:5,
      class:'Colorless',
      exhaust:false,
},
  cardText:'Whenever you apply a debuff to an enemy, they take 5 (7) damage.'
},
  'Secret Technique':{
    text:'Secret Technique',
    cats:{
      type:'Skill',
      rarity:'Rare',
      cost:0,
      value:0,
      class:'Colorless',
      exhaust:true,
},
  cardText:'Put a Skill from your draw pile into your hand. Exhaust.'
},
  'Secret Weapon':{
    text:'Secret Weapon',
    cats:{
      type:'Skill',
      rarity:'Rare',
      cost:0,
      value:0,
      class:'Colorless',
      exhaust:true,
},
  cardText:'Put an Attack from your draw pile into your hand. Exhaust.'
},
  'The Bomb':{
    text:'The Bomb',
    cats:{
      type:'Skill',
      rarity:'Rare',
      cost:2,
      value:3,
      class:'Colorless',
      exhaust:false,
},
  cardText:'At the end of 3 turns, deal 40 (50) damage to ALL enemies.'
},
  'Thinking Ahead':{
    text:'Thinking Ahead',
    cats:{
      type:'Skill',
      rarity:'Rare',
      cost:0,
      value:2,
      class:'Colorless',
      exhaust:true,
},
  cardText:'Draw 2 cards. Put a card from your hand on top of your draw pile. Exhaust.'
},
  'Transmutation':{
    text:'Transmutation',
    cats:{
      type:'Skill',
      rarity:'Rare',
      cost:'X',
      value:0,
      class:'Colorless',
      exhaust:true,
},
  cardText:'Add X random Colorless cards into your hand. They cost 0 this turn. Exhaust.'
},
  'Violence':{
    text:'Violence',
    cats:{
      type:'Skill',
      rarity:'Rare',
      cost:0,
      value:3,
      class:'Colorless',
      exhaust:true,
},
  cardText:'Put 3 (4) random Attacks from your draw pile into your hand. Exhaust.'
},
  'Apparition':{
    text:'Apparition',	
    cats:{
      type:'Skill',
      rarity:'Special',
      cost:1,
      value:1,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Ethereal. Gain 1 Intangible. Exhaust.'
  },
  'Become Almighty':{
    text:'Become Almighty',	
    cats:{
      type:'Power',
      rarity:'Special',
      cost:'Unplayable',
      value:3,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Gain 3 (4) Strength.'
  },
  'Beta':{
    text:'Beta',	
    cats:{
      type:'Skill',
      rarity:'Special',
      cost:2,
      value:0,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Shuffle an Omega into your draw pile. Exhaust.'
  },
  'Bite':{
    text:'Bite',	
    cats:{
      type:'Attack',
      rarity:'Special',
      cost:1,
      value:7,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Deal 7 (8) damage. Heal 2 (3) HP.'
  },
  'Expunger':{
    text:'Expunger',	
    cats:{
      type:'Attack',
      rarity:'Special',
      cost:1,
      value:9,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Deal 9 (15) damage X times.'
  },
  'Fame and Fortune':{
    text:'Fame and Fortune',	
    cats:{
      type:'Skill',
      rarity:'Special',
      cost:'Unplayable',
      value:25,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Gain 25 (30) Gold.'
  },
  'Insight':{
    text:'Insight',	
    cats:{
      type:'Skill',
      rarity:'Special',
      cost:0,
      value:2,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Retain. Draw 2 (3) cards. Exhaust.'
  },
  'J.A.X.':{
    text:'J.A.X.',	
    cats:{
      type:'Skill',
      rarity:'Special',
      cost:0,
      value:3,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Lose 3 HP. Gain 2 (3) Strength.'
  },
  'Live Forever':{
    text:'Live Forever',	
    cats:{
      type:'Power',
      rarity:'Special',
      cost:'Unplayable',
      value:6,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'Gain 6 (8) Plated Armor.'
  },
  'Miracle':{
    text:'Miracle',	
    cats:{
      type:'Skill',
      rarity:'Special',
      cost:0,
      value:0,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Retain. Gain [W]. Exhaust.'
  },
  'Omega':{
    text:'Omega',	
    cats:{
      type:'Power',
      rarity:'Special',
      cost:3,
      value:50,
      class:'Colorless',
      exhaust:false,
    },
    cardText:'At the end of your turn, deal 50 (60) damage to ALL enemies.'
  },
  'Ritual Dagger':{
    text:'Ritual Dagger',	
    cats:{
      type:'Attack',
      rarity:'Special',
      cost:1,
      value:15,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Deal 15 damage. If Fatal, permanently increase this card\'s damage by 3 (5). Exhaust.'
  },
  'Safety':{
    text:'Safety',	
    cats:{
      type:'Skill',
      rarity:'Special',
      cost:1,
      value:12,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Retain. Gain 12 (16) Block. Exhaust.'
  },
  'Shiv':{
    text:'Shiv',	
    cats:{
      type:'Attack',
      rarity:'Special',
      cost:0,
      value:4,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Deal 4 (6) damage. Exhaust.'
  },
  'Smite':{
    text:'Smite',	
    cats:{
      type:'Attack',
      rarity:'Special',
      cost:1,
      value:12,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Retain. Deal 12 (16) damage. Exhaust.'
  },
  'Through Violence':{
    text:'Through Violence',	
    cats:{
      type:'Attack',
      rarity:'Special',
      cost:0,
      value:20,
      class:'Colorless',
      exhaust:true,
    },
    cardText:'Retain. Deal 20 (30) damage. Exhaust.'
  },

};
const ironclad = {
  'Bash':{
    text: 'Bash',
    cats:{
      cost:2,
      type: 'Attack',
      value: 8,
      rarity: 'Basic',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Deal 8 (10) damage. Apply 2 (3) Vulnerable.'
  },
  'Anger':{
    text: 'Anger',
    cats:{
      cost:0,
      type: 'Attack',
      value: 6,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Deal 6 (8) damage. Add a copy of this card into your discard pile.'
  },
  'Armaments':{
    text: 'Armaments',
    cats:{
      cost:1,
      type: 'Skill',
      value: 5,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Gain 5 Block. Upgrade a card in your hand for the rest of combat.'
  },
  'Body Slam':{
    text: 'Body Slam',
    cats:{
      cost:1,
      type: 'Attack',
      value: 0,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Deal damage equal to your Block.'
  },
  'Clash':{
    text: 'Clash',
    cats:{
      cost:0,
      type: 'Attack',
      value: 14,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Can only be played if every card in your hand is an Attack. Deal 14 (18) damage.'
  },
  'Cleave':{
    text: 'Cleave',
    cats:{
      cost:1,
      type: 'Attack',
      value: 8,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Deal 8 (11) damage to ALL enemies.'
  },
  'Clothesline':{
    text: 'Clothesline',
    cats:{
      cost:2,
      type: 'Attack',
      value: 12,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Deal 12 (14) damage. Apply 2 (3) Weak.'
  },
  'Flex':{
    text: 'Flex',
    cats:{
      cost:0,
      type: 'Skill',
      value: 2,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Gain 2 (4) Strength. At the end of this turn, lose 2 (4) Strength.'
  },
  'Havoc':{
    text: 'Havoc',
    cats:{
      cost:1,
      type: 'Skill',
      value: 0,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Play the top card of your draw pile and Exhaust it.'
  },
  'Headbutt':{
    text: 'Headbutt',
    cats:{
      cost:1,
      type: 'Attack',
      value: 9,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Deal 9 (12) damage. Put a card from your discard pile on top of your draw pile.'
  },
  'Heavy Blade':{
    text: 'Heavy Blade',
    cats:{
      cost:2,
      type: 'Attack',
      value: 14,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Deal 14 damage. Strength affects this card 3 (5) times.'
  },
  'Iron Wave':{
    text: 'Iron Wave',
    cats:{
      cost:1,
      type: 'Attack',
      value: 5,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Gain 5 (7) Block. Deal 5 (7) damage.'
  },
  'Perfected Strike':{
    text: 'Perfected Strike',
    cats:{
      cost:2,
      type: 'Attack',
      value: 6,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Deal 6 damage. Deals 2 (3) additional damage for ALL your cards containing "Strike".'
  },
  'Pommel Strike':{
    text: 'Pommel Strike',
    cats:{
      cost:1,
      type: 'Attack',
      value: 9,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Deal 9 damage. Draw 1 card.'
  },
  'Shrug It Off':{
    text: 'Shrug It Off',
    cats:{
      cost:1,
      type: 'Skill',
      value: 8,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Gain 8 (11) Block. Draw 1 card.'
  },
  'Sword Boomerang':{
    text: 'Sword Boomerang',
    cats:{
      cost:1,
      type: 'Attack',
      value: 3,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Deal 3 damage to a random enemy 3 (4) times.'
  },
  'Thunderclap':{
    text: 'Thunderclap',
    cats:{
      cost:1,
      type: 'Attack',
      value: 4,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Deal 4 (7) damage and apply 1 Vulnerable to ALL enemies.'
  },
  'True Grit':{
    text: 'True Grit',
    cats:{
      cost:1,
      type: 'Skill',
      value: 7,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Gain 7 Block. Exhaust 1 card at random.'
  },
  'Twin Strike':{
    text: 'Twin Strike',
    cats:{
      cost:1,
      type: 'Attack',
      value: 5,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:false,
    },
    cardText:'Deal 5 (7) damage twice.'
  },
  'Warcry':{
    text: 'Warcry',
    cats:{
      cost:0,
      type: 'Skill',
      value: 1,
      rarity: 'Common',
      class: 'Ironclad',
      exhaust:true,
    },
    cardText:'Draw 1 card. Put a card from your hand onto the top of your draw pile. Exhaust.'
  },
  'Wild Strike':{
    text: 'Wild Strike',
    cats:{
      cost:1,
      type: 'Attack',
      value: 12,
      rarity: 'Common',
      
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Deal 12 (17) damage. Shuffle a Wound into your draw pile.'
  },
  'Battle Trance':{
    text: 'Battle Trance',
    cats:{
      cost:0,
      type: 'Skill',
      value: 3,
      rarity: 'Uncommon',
      
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Draw 3 (4) cards. You cannot draw additional cards this turn.'
  },
  'Blood for Blood':{
    text: 'Blood for Blood',
    cats:{
      cost:4,
      type: 'Attack',
      value: 1,
      rarity: 'Uncommon',
      
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Costs 1 less [R] for each time you lose HP this combat. Deal 18 (22) damage.'
  },
  'Bloodletting':{
    text: 'Bloodletting',
    cats:{
      cost:0,
      type: 'Skill',
      value: 3,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Lose 3 HP. Gain [R] [R].'
  },
  'Burning Pact':{
    text: 'Burning Pact',
    cats:{
      cost:1,
      type: 'Skill',
      value: 1,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Exhaust 1 card. Draw 2 (3) cards.'
  },
  'Carnage':{
    text: 'Carnage',
    cats:{
      cost:2,
      type: 'Attack',
      value: 20,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Ethereal. Deal 20 (28) damage.'
  },
  'Combust':{
    text: 'Combust',
    cats:{
      cost:1,
      type: 'Power',
      value: 1,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'At the end of your turn, lose 1 HP and deal 5 (7) damage to ALL enemies.'
  },
  'Dark Embrace':{
    text: 'Dark Embrace',
    cats:{
      cost:2,
      type: 'Power',
      value: 1,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Whenever a card is Exhausted, draw 1 card.'
  },
  'Disarm':{
    text: 'Disarm',
    cats:{
      cost:1,
      type: 'Skill',
      value: 2,
      rarity: 'Uncommon',
      exhaust:true,
      class: 'Ironclad',
    },
    cardText:'Enemy loses 2 (3) Strength. Exhaust.'
  },
  'Dropkick':{
    text: 'Dropkick',
    cats:{
      cost:1,
      type: 'Attack',
      value: 35,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Deal 5 (8) damage. If the enemy has Vulnerable, gain [R] and draw 1 card.'
  },
  'Dual Wield':{
    text: 'Dual Wield',
    cats:{
      cost:1,
      type: 'Skill',
      value: 0,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Choose an Attack or Power card. Add a copy of that card into your hand.'
  },
  'Entrench':{
    text: 'Entrench',
    cats:{
      cost:2,
      type: 'Skill',
      value: 0,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Double your Block.'
  },
  'Evolve':{
    text: 'Evolve',
    cats:{
      cost:1,
      type: 'Power',
      value: 1,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Whenever you draw a Status card, draw 1 card.'
  },
  'Feel No Pain':{
    text: 'Feel No Pain',
    cats:{
      cost:1,
      type: 'Power',
      value: 3,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Whenever a card is Exhausted, gain 3 (4) Block.'
  },
  'Fire Breathing':{
    text: 'Fire Breathing',
    cats:{
      cost:1,
      type: 'Power',
      value: 6,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Whenever you draw a Status or Curse card, deal 6 (10) damage to ALL enemies.'
  },
  'Flame Barrier':{
    text: 'Flame Barrier',
    cats:{
      cost:2,
      type: 'Skill',
      value: 12,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Gain 12 (16) Block. Whenever you are attacked this turn, deal 4 (6) damage back.'
  },
  'Ghostly Armor':{
    text: 'Ghostly Armor',
    cats:{
      cost:1,
      type: 'Skill',
      value: 10,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Ethereal. Gain 10 (13) Block.'
  },
  'Hemokinesis':{
    text: 'Hemokinesis',
    cats:{
      cost:1,
      type: 'Attack',
      value: 2,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Lose 2 HP. Deal 15 (20) damage.'
  },
  'Infernal Blade':{
    text: 'Infernal Blade',
    cats:{
      cost:1,
      type: 'Skill',
      value: 0,
      rarity: 'Uncommon',
      exhaust:true,
      class: 'Ironclad',
    },
    cardText:'Add a random Attack into your hand. It costs 0 this turn. Exhaust.'
  },
  'Inflame':{
    text: 'Inflame',
    cats:{
      cost:1,
      type: 'Power',
      value: 2,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Gain 2 (3) Strength.'
  },
  'Intimidate':{
    text: 'Intimidate',
    cats:{
      cost:0,
      type: 'Skill',
      value: 1,
      rarity: 'Uncommon',
      exhaust:true,
      class: 'Ironclad',
    },
    cardText:'Apply 1 (2) Weak to ALL enemies. Exhaust.'
  },
  'Metallicize':{
    text: 'Metallicize',
    cats:{
      cost:1,
      type: 'Power',
      value: 3,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'At the end of your turn, gain 3 (4) Block.'
  },
  'Power Through':{
    text: 'Power Through',
    cats:{
      cost:1,
      type: 'Skill',
      value: 2,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Add 2 Wounds into your hand. Gain 15 (20) Block.'
  },
  'Pummel':{
    text: 'Pummel',
    cats:{
      cost:1,
      type: 'Attack',
      value: 2,
      rarity: 'Uncommon',
      exhaust:true,
      class: 'Ironclad',
    },
    cardText:'Deal 2 damage 4 (5) times. Exhaust.'
  },
  'Rage':{
    text: 'Rage',
    cats:{
      cost:0,
      type: 'Skill',
      value: 3,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Whenever you play an Attack this turn, gain 3 (5) Block.'
  },
  'Rampage':{
    text: 'Rampage',
    cats:{
      cost:1,
      type: 'Attack',
      value: 8,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Deal 8 damage. Increase this card\'s damage by 5 (8) this combat.'
  },
  'Reckless Charge':{
    text: 'Reckless Charge',
    cats:{
      cost:0,
      type: 'Attack',
      value: 7,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Deal 7 (10) damage. Shuffle a Dazed into your draw pile.'
  },
  'Rupture':{
    text: 'Rupture',
    cats:{
      cost:1,
      type: 'Power',
      value: 1,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Whenever you lose HP from a card, gain 1 (2) Strength.'
  },
  'Searing Blow':{
    text: 'Searing Blow',
    cats:{
      cost:2,
      type: 'Attack',
      value: 12,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Deal 12 (16) damage. Can be Upgraded any number of times.'
  },
  'Second Wind':{
    text: 'Second Wind',
    cats:{
      cost:1,
      type: 'Skill',
      value: 5,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Exhaust all non-Attack cards in your hand. Gain 5 (7) Block for each card Exhausted.'
  },
  'Seeing Red':{
    text: 'Seeing Red',
    cats:{
      cost:1,
      type: 'Skill',
      value: 0,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Gain [R] [R]. Exhaust.'
  },
  'Sentinel':{
    text: 'Sentinel',
    cats:{
      cost:1,
      type: 'Skill',
      value: 5,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Gain 5 Block. If this card is Exhausted, gain [R] [R].'
  },
  'Sever Soul':{
    text: 'Sever Soul',
    cats:{
      cost:2,
      type: 'Attack',
      value: 16,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Exhaust all non-Attack cards in your hand. Deal 16 (22) damage.'
  },
  'Shockwave':{
    text: 'Shockwave',
    cats:{
      cost:2,
      type: 'Skill',
      value: 3,
      rarity: 'Uncommon',
      exhaust:true,
      class: 'Ironclad',
    },
    cardText:'Apply 3 (5) Weak and Vulnerable to ALL enemies. Exhaust.'
  },
  'Spot Weakness':{
    text: 'Spot Weakness',
    cats:{
      cost:1,
      type: 'Skill',
      value: 3,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'If the enemy intends to attack, gain 3 (4) Strength.'
  },
  'Uppercut':{
    text: 'Uppercut',
    cats:{
      cost:2,
      type: 'Attack',
      value: 13,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Deal 13 damage. Apply 1 (2) Weak. Apply 1 (2) Vulnerable.'
  },
  'Whirlwind':{
    text: 'Whirlwind',
    cats:{
      cost:'X',
      type: 'Attack',
      value: 5,
      rarity: 'Uncommon',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Deal 5 (8) damage to ALL enemies X times.'
  },
  'Barricade':{
    text: 'Barricade',
    cats:{
      cost:3,
      type: 'Power',
      value: 0,
      rarity: 'Rare',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Block is not removed at the start of your turn.'
  },
  'Berserk':{
    text: 'Berserk',
    cats:{
      cost:0,
      type: 'Power',
      value: 2,
      rarity: 'Rare',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Gain 2 (1) Vulnerable. At the start of your turn, gain [R].'
  },
  'Bludgeon':{
    text: 'Bludgeon',
    cats:{
      cost:3,
      type: 'Attack',
      value: 32,
      rarity: 'Rare',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Deal 32 (42) damage.'
  },
  'Brutality':{
    text: 'Brutality',
    cats:{
      cost:0,
      type: 'Power',
      value: 1,
      rarity: 'Rare',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'At the start of your turn, lose 1 HP and draw 1 card.'
  },
  'Corruption':{
    text: 'Corruption',
    cats:{
      cost:3,
      type: 'Power',
      value: 0,
      rarity: 'Rare',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Skills cost 0. Whenever you play a Skill, Exhaust it.'
  },
  'Demon Form':{
    text: 'Demon Form',
    cats:{
      cost:3,
      type: 'Power',
      value: 2,
      rarity: 'Rare',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'At the start of your turn, gain 2 (3) Strength.'
  },
  'Double Tap':{
    text: 'Double Tap',
    cats:{
      cost:1,
      type: 'Skill',
      value: 0,
      rarity: 'Rare',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'This turn, your next Attack is played twice.'
  },
  'Exhume':{
    text: 'Exhume',
    cats:{
      cost:1,
      type: 'Skill',
      value: 0,
      rarity: 'Rare',
      exhaust:true,
      class: 'Ironclad',
    },
    cardText:'Put a card from your exhaust pile into your hand. Exhaust.'
  },
  'Feed':{
    text: 'Feed',
    cats:{
      cost:1,
      type: 'Attack',
      value: 10,
      rarity: 'Rare',
      exhaust:true,
      class: 'Ironclad',
    },
    cardText:'Deal 10 (12) damage. If Fatal, raise your Max HP by 3 (4). Exhaust.'
  },
  'Fiend Fire':{
    text: 'Fiend Fire',
    cats:{
      cost:2,
      type: 'Attack',
      value: 7,
      rarity: 'Rare',
      exhaust:true,
      class: 'Ironclad',
    },
    cardText:'Exhaust your hand. Deal 7 (10) damage for each card Exhausted. Exhaust.'
  },
  'Immolate':{
    text: 'Immolate',
    cats:{
      cost:2,
      type: 'Attack',
      value: 21,
      rarity: 'Rare',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Deal 21 (28) damage to ALL enemies. Add a Burn into your discard pile.'
  },
  'Impervious':{
    text: 'Impervious',
    cats:{
      cost:2,
      type: 'Skill',
      value: 30,
      rarity: 'Rare',
      exhaust:true,
      class: 'Ironclad',
    },
    cardText:'Gain 30 (40) Block. Exhaust.'
  },
  'Juggernaut':{
    text: 'Juggernaut',
    cats:{
      cost:2,
      type: 'Power',
      value: 5,
      rarity: 'Rare',
      exhaust:false,
      class: 'Ironclad',
    },
    cardText:'Whenever you gain Block, deal 5 (7) damage to a random enemy.'
  },
  'Limit Break':{
    text: 'Limit Break',
    cats:{
      cost:1,
      type: 'Skill',
      value: 0,
      rarity: 'Rare',
      exhaust:true,
      class: 'Ironclad',
    },
    cardText:'Double your Strength. Exhaust.'
  },
  'Offering':{
    text: 'Offering',
    cats:{
      cost:0,
      type: 'Skill',
      value: 6,
      rarity: 'Rare',
      exhaust:true,
      class: 'Ironclad',
    },
    cardText:'Lose 6 HP. Gain [R] [R]. Draw 3 (5) cards. Exhaust.'
  },
  'Reaper':{
    text: 'Reaper',
    cats:{
      cost:2,
      type: 'Attack',
      value: 4,
      rarity: 'Rare',
      exhaust:true,
      class: 'Ironclad',
    },
    cardText:'Deal 4 (5) damage to ALL enemies. Heal HP equal to unblocked damage. Exhaust.'
  }
};
const silent = {
  'Neutralize':{
    text:'Neutralize',
    cats:{
      type:'Attack',
      rarity:'Basic',
      cost:0,
      value:3,
      exhaust:false,
      class:'Silent',
    },
    cardText:'Deal 3 (4) damage. Apply 1 (2) Weak.',
  },
  'Survivor':{
    text:'Survivor',
    cats:{
      type:'Skill',
      rarity:'Basic',
      cost:1,
      value:8,
      exhaust:false,
      class:'Silent',
    },
    cardText:'Gain 8 (11) Block. Discard 1 card.',
  },
'Acrobatics':{
  text:'Acrobatics',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    value:3,
    exhaust:false,
    class:'Silent',
  }, 
  cardText:'Draw 3 (4) cards. Discard 1 card.',
},
'Backflip':{
  text:'Backflip',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    value:5,
    exhaust:false,
    class:'Silent',
  }, 
  cardText:'Gain 5 (8) Block. Draw 2 cards.',
},
'Bane':{
  text:'Bane',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    value:7,
    exhaust:false,
    class:'Silent',
  }, 
  cardText:'Deal 7 (10) damage. If the enemy has Poison, deal 7 (10) damage again.',
},
'Blade Dance':{
  text:'Blade Dance',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    value:3,
    exhaust:false,
    class:'Silent',
  }, 
  cardText:'Add 3 (4) Shivs into your hand.',
},
'Cloak and Dagger':{
  text:'Cloak and Dagger',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    value:6,
    exhaust:false,
    class:'Silent',
  }, 
  cardText:'Gain 6 Block. Add 1 Shiv into your hand.',
},
'Dagger Spray':{
  text:'Dagger Spray',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    value:4,
    exhaust:false,
    class:'Silent',
  }, 
  cardText:'Deal 4 (6) damage to ALL enemies twice.',
},
'Dagger Throw':{
  text:'Dagger Throw',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    value:9,
    exhaust:false,
    class:'Silent',
  }, 
  cardText:'Deal 9 (12) damage. Draw 1 card. Discard 1 card.',
},
'Deadly Poison':{
  text:'Deadly Poison',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    value:5,
    exhaust:false,
    class:'Silent',
  }, 
  cardText:'Apply 5 (7) Poison.',
},
'Deflect':{
  text:'Deflect',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:0,
    value:4,
    exhaust:false,
    class:'Silent',
  }, 
  cardText:'Gain 4 (7) Block.',
},
'Dodge and Roll':{
  text:'Dodge and Roll',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    value:4,
    exhaust:false,
    class:'Silent',
  }, 
  cardText:'Gain 4 (6) Block. Next turn, gain 4 (6) Block.',
},
'Flying Knee':{
  text:'Flying Knee',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    value:8,
    exhaust: false,
    class:'Silent',
  },
  cardText:'Deal 8 (11) damage. Next turn, gain [G].',
},
'Outmaneuver':{
  text:'Outmaneuver',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    value:0,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Next turn, gain [G] [G].',
},
'Piercing Wail':{
  text:'Piercing Wail',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    value:6,
    exhaust:true,
    class:'Silent',
  	},
  cardText:'ALL enemies lose 6 (8) Strength this turn. Exhaust.',
},
'Poisoned Stab':{
  text:'Poisoned Stab',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    value:6,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 6 (8) damage. Apply 3 (4) Poison.',
},
'Prepared':{
  text:'Prepared',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:0,
    value:1,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Draw 1 card. Discard 1 card.',
},
'Quick Slash':{
  text:'Quick Slash',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    value:8,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 8 (12) damage. Draw 1 card.',
},
'Slice':{
  text:'Slice',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:0,
    value:6,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 6 (9) damage.',
},
'Sneaky Strike':{
  text:'Sneaky Strike',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:2,
    value:12,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 12 (16) damage. If you have discarded a card this turn, gain [G] [G].',
},
'Sucker Punch':{
  text:'Sucker Punch',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    value:7,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 7 (9) damage. Apply 1 (2) Weak.',
},
'Accuracy':{
  text:'Accuracy',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    value:4,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Shivs deal 4 (6) additional damage.',
},
'All-Out Attack':{
  text:'All-Out Attack',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    value:10,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 10 (14) damage to ALL enemies. Discard 1 card at random.',
},
'Backstab':{
  text:'Backstab',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:0,
    value:11,
    exhaust:true,
    class:'Silent',
  	},
  cardText:'Innate. Deal 11 (15) damage. Exhaust.',
},
'Blur':{
  text:'Blur',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    value:5,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Gain 5 (8) Block. Block is not removed at the start of your next turn.',
},
'Bouncing Flask':{
  text:'Bouncing Flask',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:2,
    value:3,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Apply 3 Poison to a random enemy 3 (4) times.',
},
'Calculated Gamble':{
  text:'Calculated Gamble',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:0,
    value:0,
    exhaust:true,
    class:'Silent',
  	},
  cardText:'Discard your hand, then draw that many cards. Exhaust.',
},
'Caltrops':{
  text:'Caltrops',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    value:3,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Whenever you are attacked, deal 3 (5) damage back.',
},
'Catalyst':{
  text:'Catalyst',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    value:0,
    exhaust:true,
    class:'Silent',
  	},
  cardText:'Double the enemy\'s Poison. Exhaust.',
},
'Choke':{
  text:'Choke',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:2,
    value:12,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 12 damage. Whenever you play a card this turn, the enemy loses 3 (5) HP.',
},
'Concentrate':{
  text:'Concentrate',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:0,
    value:3,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Discard 3 (2) cards. Gain [G] [G].',
},
'Crippling Cloud':{
  text:'Crippling Cloud',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:2,
    value:4,
    exhaust:true,
    class:'Silent',
  	},
  cardText:'Apply 4 (7) Poison and 2 Weak to ALL enemies. Exhaust.',
},
'Dash':{
  text:'Dash',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:2,
    value:10,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Gain 10 (13) Block. Deal 10 (13) damage.',
},
'Distraction':{
  text:'Distraction',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    value:0,
    exhaust:true,
    class:'Silent',
   },
  cardText:'Add a random Skill into your hand. It costs 0 this turn. Exhaust.',
},
'Endless Agony':{
  text:'Endless Agony',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:0,
    value:4,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 4 (6) damage. Whenever you draw this card, add a copy of it into your hand. Exhaust.',
},
'Escape Plan':{
  text:'Escape Plan',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:0,
    value:1,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Draw 1 card. If you draw a Skill, gain 3 (5) Block.',
},
'Eviscerate':{
  text:'Eviscerate',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:3,
    value:1,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Costs 1 less [G] for each card discarded this turn. Deal 7 (9) damage 3 times.',
},
'Expertise':{
  text:'Expertise',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    value:6,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Draw cards until you have 6 (7) in your hand.',
},
'Finisher':{
  text:'Finisher',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    value:6,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 6 (8) damage for each Attack played this turn.',
},
'Flechettes':{
  text:'Flechettes',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    value:4,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 4 (6) damage for each Skill in your hand.',
},
'Footwork':{
  text:'Footwork',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    value:2,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Gain 2 (3) Dexterity.',
},
'Heel Hook':{
  text:'Heel Hook',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    value:5,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 5 (8) damage. If the enemy has Weak, gain [G] and draw 1 card.',
},
'Infinite Blades':{
  text:'Infinite Blades',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    value:0,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'At the start of your turn, add a Shiv into your hand.',
},
'Leg Sweep':{
  text:'Leg Sweep',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:2,
    value:2,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Apply 2 (3) Weak. Gain 11 (14) Block.',
},
'Masterful Stab':{
  text:'Masterful Stab',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:0,
    value:1,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Costs 1 additional [G] for each time you lose HP this combat. Deal 12 (16) damage.',
},
'Noxious Fumes':{
  text:'Noxious Fumes',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    value:2,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'At the start of your turn, apply 2 (3) Poison to ALL enemies.',
},
'Predator':{
  text:'Predator',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:2,
    value:15,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 15 (20) damage. Next turn, draw 2 additional cards.',
},
'Reflex':{
  text:'Reflex',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:'Unplayable',
    value:2,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Unplayable. If this card is discarded from your hand, draw 2 cards.',
},
'Riddle with Holes':{
  text:'Riddle with Holes',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:2,
    value:3,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 3 (4) damage 5 times.',
},
'Setup':{
  text:'Setup',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    value:0,
    exhaust:false,
    class:'Silent',
   },
  cardText:'Put a card from your hand on top of your draw pile. It costs 0 until played.',
},
'Skewer':{
  text:'Skewer',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:'X',
    value:7,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 7 (10) damage X times.',
},
'Tactician':{
  text:'Tactician',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:'Unplayable',
    value:0,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Unplayable. If this card is discarded from your hand, gain [G].',
},
'Terror':{
  text:'Terror',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    value:99,
    exhaust:true,
    class:'Silent',
   },
  cardText:'Apply 99 Vulnerable. Exhaust.',
},
'Well-Laid Plans':{
  text:'Well-Laid Plans',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    value:1,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'At the end of your turn, Retain up to 1 card.',
},
'A Thousand Cuts':{
  text:'A Thousand Cuts',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:2,
    value:1,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Whenever you play a card, deal 1 (2) damage to ALL enemies.',
},
'Adrenaline':{
  text:'Adrenaline',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:0,
    value:2,
    exhaust:true,
    class:'Silent',
  	},
  cardText:'Gain [G]. Draw 2 cards. Exhaust.',
},
'After Image':{
  text:'After Image',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:1,
    value:1,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Whenever you play a card, gain 1 Block.',
},
'Alchemize':{
  text:'Alchemize',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:1,
    value:0,
    exhaust:true,
    class:'Silent',
   },
  cardText:'(0)	Obtain a random potion. Exhaust.',
},
'Bullet Time':{
  text:'Bullet Time',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:3,
    value:0,
    exhaust:false,
    class:'Silent',
   },
  cardText:'You cannot draw additional cards this turn. Reduce the cost of all cards in your hand to 0 this turn.',
},
'Burst':{
  text:'Burst',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:1,
    value:0,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'This turn, your next Skill is played twice.',
},
'Corpse Explosion':{
  text:'Corpse Explosion',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:2,
    value:6,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Apply 6 (9) Poison. When the enemy dies, deal damage equal to its Max HP to ALL enemies.',
},
'Die Die Die':{
  text:'Die Die Die',
  cats:{
    type:'Attack',
    rarity:'Rare',
    cost:1,
    value:13,
    exhaust:true,
    class:'Silent',
  	},
  cardText:'Deal 13 (17) damage to ALL enemies. Exhaust.',
},
'Doppelganger':{
  text:'Doppelganger',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:'X',
    value:0,
    exhaust:true,
    class:'Silent',
  	},
  cardText:'Next turn, draw X cards and gain X [G]. Exhaust.',
},
'Envenom':{
  text:'Envenom',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:2,
    value:1,
    exhaust:false,
    class:'Silent',
   },
  cardText:'Whenever an Attack deals unblocked damage, apply 1 Poison.',
},
'Glass Knife':{
  text:'Glass Knife',
  cats:{
    type:'Attack',
    rarity:'Rare',
    cost:1,
    value:8,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 8 (12) damage twice. Decrease the damage of this card by 2 this combat.',
},
'Grand Finale':{
  text:'Grand Finale',
  cats:{
    type:'Attack',
    rarity:'Rare',
    cost:0,
    value:50,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Can only be played if there are no cards in your draw pile. Deal 50 (60) damage to ALL enemies.',
},
'Malaise':{
  text:'Malaise',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:'X',
    value:0,
    exhaust:true,
    class:'Silent',
  	},
  cardText:'Enemy loses X Strength. Apply X Weak. Exhaust.',
},
'Nightmare':{
  text:'Nightmare',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:3,
    value:3,
    exhaust:true,
    class:'Silent',
   },
  cardText:'Choose a card. Next turn, add 3 copies of that card into your hand. Exhaust.',
},
'Phantasmal Killer':{
  text:'Phantasmal Killer',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:1,
    value:0,
    exhaust:false,
    class:'Silent',
   },
  cardText:'Next turn, your Attacks deal double damage.',
},
'Storm of Steel':{
  text:'Storm of Steel',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:1,
    value:1,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Discard your hand. Add 1 Shiv into your hand for each card discarded.',
},
'Tools of the Trade':{
  text:'Tools of the Trade',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:1,
    value:1,
    exhaust:false,
    class:'Silent',
   },
  cardText:'At the start of your turn, draw 1 card and discard 1 card.',
},
'Unload':{
  text:'Unload',
  cats:{
    type:'Attack',
    rarity:'Rare',
    cost:1,
    value:14,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Deal 14 (18) damage. Discard all non-Attack cards in your hand.',
},
'Wraith Form':{
  text:'Wraith Form',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:3,
    value:2,
    exhaust:false,
    class:'Silent',
  	},
  cardText:'Gain 2 (3) Intangible. At the end of your turn, lose 1 Dexterity.',
},
};
const defect = {
'Dualcast':{
  text:'Dualcast',
  cats:{
    type:'Skill',
    rarity:'Basic',
    cost:1,
    class:'Defect',
    value:0,
    exhaust:false,
  },
  cardText:'Evoke your next Orb twice.'
  },
'Zap':{
  text:'Zap',
  cats:{
    type:'Skill',
    rarity:'Basic',
    cost:1,
    class:'Defect',
    value:1,
    exhaust:false,
  },
  cardText:'Channel 1 Lightning.'
  },
'Ball Lightning':{
  text:'Ball Lightning',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    class:'Defect',
    value:7,
    exhaust:false,
  },
  cardText:'Deal 7 (10) damage. Channel 1 Lightning.'
  },
'Barrage':{
  text:'Barrage',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    class:'Defect',
    value:4,
    exhaust:false,
  },
  cardText:'Deal 4 (6) damage for each Channeled Orb.'
  },
'Beam Cell':{
  text:'Beam Cell',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:0,
    class:'Defect',
    value:3,
    exhaust:false,
  },
  cardText:'Deal 3 (4) damage. Apply 1 (2) Vulnerable.'
  },
'Charge Battery':{
  text:'Charge Battery',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    class:'Defect',
    value:7,
    exhaust:false,
  },
  cardText:'Gain 7 (10) Block. Next turn, gain [B].'
  },
'Claw':{
  text:'Claw',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:0,
    class:'Defect',
    value:3,
    exhaust:false,
  },
  cardText:'Deal 3 (5) damage. Increase the damage of ALL Claw cards by 2 this combat.'
  },
'Cold Snap':{
  text:'Cold Snap',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    class:'Defect',
    value:6,
    exhaust:false,
  },
  cardText:'Deal 6 (9) damage. Channel 1 Frost.'
  },
'Compile Driver':{
  text:'Compile Driver',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    class:'Defect',
    value:7,
    exhaust:false,
  },
  cardText:'Deal 7 (10) damage. Draw 1 card for each unique Orb you have.'
  },
'Coolheaded':{
  text:'Coolheaded',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    class:'Defect',
    value:1,
    exhaust:false,
  },
  cardText:'Channel 1 Frost. Draw 1 card.'
  },
'Go for the Eyes':{
  text:'Go for the Eyes',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:0,
    class:'Defect',
    value:3,
    exhaust:false,
  },
  cardText:'Deal 3 (4) damage. If the enemy intends to attack, apply 1 (2) Weak.'
  },
'Hologram':{
  text:'Hologram',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    class:'Defect',
    value:3,
    exhaust:true,
  },
  cardText:'Gain 3 Block. Put a card from your discard pile into your hand. Exhaust.'
  },
'Leap':{
  text:'Leap',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    class:'Defect',
    value:9,
    exhaust:false,
  },
  cardText:'Gain 9 (12) Block.'
  },
'Rebound':{
  text:'Rebound',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    class:'Defect',
    value:9,
    exhaust:false,
  },
  cardText:'Deal 9 (12) damage. Put the next card you play this turn on top of your draw pile.'
  },
'Recursion':{
  text:'Recursion',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    class:'Defect',
    value:0,
    exhaust:false,
  },
  cardText:'Evoke your next Orb. Channel the Orb that was just Evoked.'
  },
'Stack':{
  text:'Stack',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    class:'Defect',
    value:0,
    exhaust:false,
  },
  cardText:'Gain Block equal to the number of cards in your discard pile.'
  },
'Steam Barrier':{
  text:'Steam Barrier',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:0,
    class:'Defect',
    value:6,
    exhaust:false,
  },
  cardText:'Gain 6 (8) Block. Decrease this card\'s Block by 1 this combat.'
  },
'Streamline':{
  text:'Streamline',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:2,
    class:'Defect',
    value:15,
    exhaust:false,
  },
  cardText:'Deal 15 (20) damage. Reduce this card\'s cost by 1 this combat.'
  },
'Sweeping Beam':{
  text:'Sweeping Beam',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    class:'Defect',
    value:6,
    exhaust:false,
  },
  cardText:'Deal 6 (9) damage to ALL enemies. Draw 1 card.'
  },
'TURBO':{
  text:'TURBO',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:0,
    class:'Defect',
    value:0,
    exhaust:false,
  },
  cardText:'Gain [B] [B]. Add a Void into your discard pile.'
  },
'Aggregate':{
  text:'Aggregate',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:4,
    exhaust:false,
  },
  cardText:'Gain [B] for every 4 (3) cards in your draw pile.'
  },
'Auto-Shields':{
  text:'Auto-Shields',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:11,
    exhaust:false,
  },
  cardText:'If you have no Block, gain 11 (15) Block.'
  },
'Blizzard':{
  text:'Blizzard',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:2,
    exhaust:false,
  },
  cardText:'Deal damage equal to 2 (3) times the number of Frost Channeled this combat to ALL enemies.'
  },
'Boot Sequence':{
  text:'Boot Sequence',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:0,
    class:'Defect',
    value:10,
    exhaust:false,
  },
  cardText:'Innate. Gain 10 (13) Block. Exhaust.'
  },
'Bullseye':{
  text:'Bullseye',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:8,
    exhaust:false,
  },
  cardText:'Deal 8 (11) damage. Apply 2 (3) Lock-On.'
  },
'Capacitor':{
  text:'Capacitor',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:2,
    exhaust:false,
  },
  cardText:'Gain 2 (3) Orb slots.'
  },
'Chaos':{
  text:'Chaos',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:1,
    exhaust:false,
  },
  cardText:'Channel 1 random Orb.'
  },
'Chill':{
  text:'Chill',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:0,
    class:'Defect',
    value:1,
    exhaust:false,
  },
  cardText:'Channel 1 Frost for each enemy in combat. Exhaust.'
  },
'Consume':{
  text:'Consume',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:2,
    class:'Defect',
    value:2,
    exhaust:false,
  },
  cardText:'Gain 2 (3) Focus. Lose 1 Orb slot.'
  },
'Darkness':{
  text:'Darkness',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:1,
    exhaust:false,
  },
  cardText:'Channel 1 Dark.'
  },
'Defragment':{
  text:'Defragment',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:1,
    exhaust:false,
  },
  cardText:'Gain 1 (2) Focus.'
  },
'Doom and Gloom':{
  text:'Doom and Gloom',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:2,
    class:'Defect',
    value:10,
    exhaust:false,
  },
  cardText:'Deal 10 (14) damage to ALL enemies. Channel 1 Dark.'
  },
'Double Energy':{
  text:'Double Energy',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:0,
    exhaust:true,
  },
  cardText:'Double your Energy. Exhaust.'
  },
'Equilibrium':{
  text:'Equilibrium',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:2,
    class:'Defect',
    value:13,
    exhaust:false,
  },
  cardText:'Gain 13 (16) Block. Retain your hand this turn.'
  },
'FTL':{
  text:'FTL',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:0,
    class:'Defect',
    value:5,
    exhaust:false,
  },
  cardText:'Deal 5 (6) damage. If you have played less than 3 (4) cards this turn, draw 1 card.'
  },
'Force Field':{
  text:'Force Field',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:4,
    class:'Defect',
    value:1,
    exhaust:false,
  },
  cardText:'Costs 1 less [B] for each Power card played this combat. Gain 12 (16) Block.'
  },
'Fusion':{
  text:'Fusion',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:2,
    class:'Defect',
    value:1,
    exhaust:false,
  },
  cardText:'Channel 1 Plasma.'
  },
'Genetic Algorithm':{
  text:'Genetic Algorithm',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:1,
    exhaust:true,
  },
  cardText:'Gain 1 Block. Permanently increase this card\'s Block by 2 (3). Exhaust.'
  },
'Glacier':{
  text:'Glacier',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:2,
    class:'Defect',
    value:7,
    exhaust:false,
  },
  cardText:'Gain 7 (10) Block. Channel 2 Frost.'
  },
'Heatsinks':{
  text:'Heatsinks',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:1,
    exhaust:false,
  },
  cardText:'Whenever you play a Power card, draw 1 card.'
  },
'Hello World':{
  text:'Hello World',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:0,
    exhaust:false,
  },
  cardText:'At the start of your turn, add a random Common card into your hand.'
  },
'Loop':{
  text:'Loop',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:0,
    exhaust:false,
  },
  cardText:'At the start of your turn, trigger the passive ability of your next Orb.'
  },
'Melter':{
  text:'Melter',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:10,
    exhaust:false,
  },
  cardText:'Remove all Block from the enemy. Deal 10 (14) damage.'
  },
'Overclock':{
  text:'Overclock',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:0,
    class:'Defect',
    value:2,
    exhaust:false,
  },
  cardText:'Draw 2 (3) cards. Add a Burn into your discard pile.'
  },
'Recycle':{
  text:'Recycle',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:0,
    exhaust:false,
  },
  cardText:'Exhaust a card. Gain [B] equal to its cost.'
  },
'Reinforced Body':{
  text:'Reinforced Body',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:'X',
    class:'Defect',
    value:7,
    exhaust:false,
  },
  cardText:'Gain 7 (9) Block X times.'
  },
'Reprogram':{
  text:'Reprogram',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:1,
    exhaust:false,
  },
  cardText:'Lose 1 (2) Focus. Gain 1 (2) Strength. Gain 1 (2) Dexterity.'
  },
'Rip and Tear':{
  text:'Rip and Tear',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:7,
    exhaust:false,
  },
  cardText:'Deal 7 (9) damage to a random enemy twice.'
  },
'Scrape':{
  text:'Scrape',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:7,
    exhaust:false,
  },
  cardText:'Deal 7 (10) damage. Draw 4 (5) cards. Discard all cards drawn this way that do not cost 0.'
  },
'Self Repair':{
  text:'Self Repair',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:7,
    exhaust:false,
  },
  cardText:'At the end of combat, heal 7 (10) HP.'
  },
'Skim':{
  text:'Skim',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:3,
    exhaust:false,
  },
  cardText:'Draw 3 (4) cards.'
  },
'Static Discharge':{
  text:'Static Discharge',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:1,
    exhaust:false,
  },
  cardText:'Whenever you receive unblocked attack damage, Channel 1 (2) Lightning.'
  },
'Storm':{
  text:'Storm',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:1,
    exhaust:false,
  },
  cardText:'Whenever you play a Power card, Channel 1 Lightning.'
  },
'Sunder':{
  text:'Sunder',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:3,
    class:'Defect',
    value:24,
    exhaust:false,
  },
  cardText:'Deal 24 (32) damage. If this kills an enemy, gain [B] [B] [B].'
  },
'Tempest':{
  text:'Tempest',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:'X',
    class:'Defect',
    value:0,
    exhaust:true,
  },
  cardText:'Channel X Lightning. Exhaust.'
  },
'White Noise':{
  text:'White Noise',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Defect',
    value:0,
    exhaust:true,
  },
  cardText:'Add a random Power card into your hand. It costs 0 this turn. Exhaust.'
  },
'All for One':{
  text:'All for One',
  cats:{
    type:'Attack',
    rarity:'Rare',
    cost:2,
    class:'Defect',
    value:10,
    exhaust:false,
  },
  cardText:'Deal 10 (14) damage. Put all cost 0 cards from your discard pile into your hand.'
  },
'Amplify':{
  text:'Amplify',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:1,
    class:'Defect',
    value:0,
    exhaust:false,
  },
  cardText:'This turn, your next Power card is played twice.'
  },
'Biased Cognition':{
  text:'Biased Cognition',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:1,
    class:'Defect',
    value:4,
    exhaust:false,
  },
  cardText:'Gain 4 (5) Focus. At the start of your turn, lose 1 Focus.'
  },
'Buffer':{
  text:'Buffer',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:2,
    class:'Defect',
    value:0,
    exhaust:false,
  },
  cardText:'Prevent the next time you would lose HP.'
  },
'Core Surge':{
  text:'Core Surge',
  cats:{
    type:'Attack',
    rarity:'Rare',
    cost:1,
    class:'Defect',
    value:11,
    exhaust:false,
  },
  cardText:'Deal 11 (15) damage. Gain 1 Artifact. Exhaust.'
  },
'Creative AI':{
  text:'Creative AI',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:3,
    class:'Defect',
    value:0,
    exhaust:false,
  },
  cardText:'At the start of your turn, add a random Power card into your hand.'
  },
'Echo Form':{
  text:'Echo Form',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:3,
    class:'Defect',
    value:0,
    exhaust:false,
  },
  cardText:'Ethereal. The first card you play each turn is played twice.'
  },
'Electrodynamics':{
  text:'Electrodynamics',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:2,
    class:'Defect',
    value:2,
    exhaust:false,
  },
  cardText:'Lightning now hits ALL enemies. Channel 2 (3) Lightning.'
  },
'Fission':{
  text:'Fission',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:0,
    class:'Defect',
    value:1,
    exhaust:true,
  },
  cardText:'Remove all your Orbs. Gain [B] and draw 1 card for each Orb removed. Exhaust.'
  },
'Hyperbeam':{
  text:'Hyperbeam',
  cats:{
    type:'Attack',
    rarity:'Rare',
    cost:2,
    class:'Defect',
    value:26,
    exhaust:false,
  },
  cardText:'Deal 26 (34) damage to ALL enemies. Lose 3 Focus.'
  },
'Machine Learning':{
  text:'Machine Learning',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:1,
    class:'Defect',
    value:1,
    exhaust:false,
  },
  cardText:'At the start of your turn, draw 1 additional card.'
  },
'Meteor Strike':{
  text:'Meteor Strike',
  cats:{
    type:'Attack',
    rarity:'Rare',
    cost:5,
    class:'Defect',
    value:24,
    exhaust:false,
  },
  cardText:'Deal 24 (30) damage. Channel 3 Plasma.'
  },
'Multi-Cast':{
  text:'Multi-Cast',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:'X',
    class:'Defect',
    value:0,
    exhaust:false,
  },
  cardText:'Evoke your next Orb X times.'
  },
'Rainbow':{
  text:'Rainbow',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:2,
    class:'Defect',
    value:1,
    exhaust:true,
  },
  cardText:'Channel 1 Lightning. Channel 1 Frost. Channel 1 Dark. Exhaust.'
  },
'Reboot':{
  text:'Reboot',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:0,
    class:'Defect',
    value:4,
    exhaust:true,
  },
  cardText:'Shuffle ALL your cards into your draw pile. Draw 4 (6) cards. Exhaust.'
  },
'Seek':{
  text:'Seek',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:0,
    class:'Defect',
    value:1,
    exhaust:true,
  },
  cardText:'Put 1 card from your draw pile into your hand. Exhaust.'
  },
'Thunder Strike':{
  text:'Thunder Strike',
  cats:{
    type:'Attack',
    rarity:'Rare',
    cost:3,
    class:'Defect',
    value:7,
    exhaust:false,
  },
  cardText:'Deal 7 (9) damage to a random enemy for each Lightning Channeled this combat.'
  },
};
const watcher = {
'Eruption':{
  text:'Eruption',
  cats:{
    type:'Attack',
    rarity:'Basic',
    cost:2,
    class:'Watcher',
    value:9,
    exhaust:false,
  },
  cardText:'Deal 9 damage. Enter Wrath.'
  },
'Vigilance':{
  text:'Vigilance',
  cats:{
    type:'Skill',
    rarity:'Basic',
    cost:2,
    class:'Watcher',
    value:8,
    exhaust:false,
  },
  cardText:'Gain 8 (12) Block. Enter Calm.'
  },
'Bowling Bash':{
  text:'Bowling Bash',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    class:'Watcher',
    value:7,
    exhaust:false,
  },
  cardText:'Deal 7 (10) damage for each enemy in combat.'
  },
'Consecrate':{
  text:'Consecrate',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:0,
    class:'Watcher',
    value:5,
    exhaust:false,
  },
  cardText:'Deal 5 (8) damage to ALL enemies.'
  },
'Crescendo':{
  text:'Crescendo',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    class:'Watcher',
    value:0,
    exhaust:true,
  },
  cardText:'Retain. Enter Wrath. Exhaust.'
  },
'Crush Joints':{
  text:'Crush Joints',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    class:'Watcher',
    value:8,
    exhaust:false,
  },
  cardText:'Deal 8 (10) damage. If the last card played this combat was a Skill, apply 1 (2) Vulnerable.'
  },
'Cut Through Fate':{
  text:'Cut Through Fate',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    class:'Watcher',
    value:7,
    exhaust:false,
  },
  cardText:'Deal 7 (9) damage. Scry 2 (3). Draw 1 card.'
  },
'Empty Body':{
  text:'Empty Body',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    class:'Watcher',
    value:7,
    exhaust:false,
  },
  cardText:'Gain 7 (10) Block. Exit your Stance.'
  },
'Empty Fist':{
  text:'Empty Fist',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    class:'Watcher',
    value:8,
    exhaust:false,
  },
  cardText:'Deal 9 (14) damage. Exit your Stance.'
  },
'Evaluate':{
  text:'Evaluate',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    class:'Watcher',
    value:6,
    exhaust:false,
  },
  cardText:'Gain 6 (10) Block. Shuffle an Insight into your draw pile.'
  },
'Flurry of Blows':{
  text:'Flurry of Blows',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:0,
    class:'Watcher',
    value:4,
    exhaust:false,
  },
  cardText:'Deal 4 (6) damage. Whenever you change Stances, return this from the discard pile to your hand.'
  },
'Flying Sleeves':{
  text:'Flying Sleeves',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    class:'Watcher',
    value:4,
    exhaust:false,
  },
  cardText:'Retain. Deal 4 (6) damage twice.'
  },
'Follow-Up':{
  text:'Follow-Up',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    class:'Watcher',
    value:7,
    exhaust:false,
  },
  cardText:'Deal 7 (11) damage. If the last card played this combat was an Attack, gain [W].'
  },
'Halt':{
  text:'Halt',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:0,
    class:'Watcher',
    value:3,
    exhaust:false,
  },
  cardText:'Gain 3 (4) Block. If you are in Wrath, gain 9 (14) additional Block.'
  },
'Just Lucky':{
  text:'Just Lucky',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:0,
    class:'Watcher',
    value:1,
    exhaust:false,
  },
  cardText:'Scry 1 (2). Gain 2 (3) Block. Deal 3 (4) damage.'
  },
'Pressure Points':{
  text:'Pressure Points',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    class:'Watcher',
    value:8,
    exhaust:false,
  },
  cardText:'Apply 8 (11) Mark. ALL enemies lose HP equal to their Mark.'
  },
'Prostrate':{
  text:'Prostrate',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:0,
    class:'Watcher',
    value:2,
    exhaust:false,
  },
  cardText:'Gain 2 (3) Mantra. Gain 4 Block.'
  },
'Protect':{
  text:'Protect',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:2,
    class:'Watcher',
    value:12,
    exhaust:false,
  },
  cardText:'Retain. Gain 12 (16) Block.'
  },
'Sash Whip':{
  text:'Sash Whip',
  cats:{
    type:'Attack',
    rarity:'Common',
    cost:1,
    class:'Watcher',
    value:8,
    exhaust:false,
  },
  cardText:'Deal 8 (10) damage. If the last card played this combat was an Attack, apply 1 (2) Weak.'
  },
'Third Eye':{
  text:'Third Eye',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    class:'Watcher',
    value:7,
    exhaust:false,
  },
  cardText:'Gain 7 (9) Block. Scry 3 (5).'
  },
'Tranquility':{
  text:'Tranquility',
  cats:{
    type:'Skill',
    rarity:'Common',
    cost:1,
    class:'Watcher',
    value:0,
    exhaust:true,
  },
  cardText:'Retain. Enter Calm. Exhaust.'
  },
'Battle Hymn':{
  text:'Battle Hymn',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:0,
    exhaust:false,
  },
  cardText:'At the start of each turn, add a Smite into your hand.'
  },
'Carve Reality':{
  text:'Carve Reality',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:6,
    exhaust:false,
  },
  cardText:'Deal 6 (10) damage. Add a Smite into your hand.'
  },
'Collect':{
  text:'Collect',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:'X',
    class:'Watcher',
    value:0,
    exhaust:true,
  },
  cardText:'Put a Miracle+ into your hand at the start of your next X turns. Exhaust.'
  },
'Conclude':{
  text:'Conclude',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:12,
    exhaust:false,
  },
  cardText:'Deal 12 (16) damage to ALL enemies. End your turn.'
  },
'Deceive Reality':{
  text:'Deceive Reality',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:4,
    exhaust:false,
  },
  cardText:'Gain 4 (7) Block. Add a Safety into your hand.'
  },
'Empty Mind':{
  text:'Empty Mind',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:2,
    exhaust:false,
  },
  cardText:'Draw 2 (3) cards. Exit your Stance.'
  },
'Fasting':{
  text:'Fasting',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:2,
    class:'Watcher',
    value:3,
    exhaust:false,
  },
  cardText:'Gain 3 (4) Strength. Gain 3 (4) Dexterity. Gain 1 less [W] at the start of each turn.'
  },
'Fear No Evil':{
  text:'Fear No Evil',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:8,
    exhaust:false,
  },
  cardText:'Deal 8 (11) damage. If the enemy intends to Attack, enter Calm.'
  },
'Foreign Influence':{
  text:'Foreign Influence',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:0,
    class:'Watcher',
    value:1,
    exhaust:true,
  },
  cardText:'Choose 1 of 3 Attacks of any color to add into your hand. Exhaust.'
  },
'Foresight':{
  text:'Foresight',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:3,
    exhaust:false,
  },
  cardText:'At the start of your turn, Scry 3 (4).'
  },
'Indignation':{
  text:'Indignation',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:3,
    exhaust:false,
  },
  cardText:'If you are in Wrath, apply 3 (5) Vulnerable to ALL enemies, otherwise enter Wrath.'
  },
'Inner Peace':{
  text:'Inner Peace',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:3,
    exhaust:false,
  },
  cardText:'If you are in Calm, draw 3 (4) cards, otherwise enter Calm.'
  },
'Like Water':{
  text:'Like Water',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:5,
    exhaust:false,
  },
  cardText:'At the end of your turn, if you are in Calm, gain 5 (7) Block.'
  },
'Meditate':{
  text:'Meditate',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:0,
    exhaust:false,
  },
  cardText:'Put a card from your discard pile into your hand and Retain it. Enter Calm. End your turn.'
  },
'Mental Fortress':{
  text:'Mental Fortress',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:4,
    exhaust:false,
  },
  cardText:'Whenever you change Stances, gain 4 (6) Block.'
  },
'Nirvana':{
  text:'Nirvana',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:3,
    exhaust:false,
  },
  cardText:'Whenever you Scry, gain 3 (4) Block.'
  },
'Perseverance':{
  text:'Perseverance',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:5,
    exhaust:false,
  },
  cardText:'Retain. Gain 5 (7) Block. When Retained, increase its Block by 2 (3) this combat.'
  },
'Pray':{
  text:'Pray',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:3,
    exhaust:false,
  },
  cardText:'Gain 3 (4) Mantra. Shuffle an Insight into your draw pile.'
  },
'Reach Heaven':{
  text:'Reach Heaven',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:2,
    class:'Watcher',
    value:10,
    exhaust:false,
  },
  cardText:'Deal 10 (15) damage. Shuffle a Through Violence into your draw pile.'
  },
'Rushdown':{
  text:'Rushdown',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:2,
    exhaust:false,
  },
  cardText:'Whenever you enter Wrath, draw 2 cards.'
  },
'Sanctity':{
  text:'Sanctity',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:6,
    exhaust:false,
  },
  cardText:'Gain 6 (9) Block. If the last card played this combat was a Skill, draw 2 cards.'
  },
'Sands of Time':{
  text:'Sands of Time',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:4,
    class:'Watcher',
    value:20,
    exhaust:false,
  },
  cardText:'Retain. Deal 20 (26) damage. When Retained, lower its cost by 1 this combat.'
  },
'Signature Move':{
  text:'Signature Move',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:2,
    class:'Watcher',
    value:30,
    exhaust:false,
  },
  cardText:'Can only be played if this is the only Attack in your hand. Deal 30 (40) damage.'
  },
'Simmering Fury':{
  text:'Simmering Fury',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:2,
    exhaust:false,
  },
  cardText:'At the start of your next turn, enter Wrath and draw 2 (3) cards.'
  },
'Study':{
  text:'Study',
  cats:{
    type:'Power',
    rarity:'Uncommon',
    cost:2,
    class:'Watcher',
    value:0,
    exhaust:false,
  },
  cardText:'At the end of your turn, shuffle an Insight into your draw pile.'
  },
'Swivel':{
  text:'Swivel',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:2,
    class:'Watcher',
    value:8,
    exhaust:false,
  },
  cardText:'Gain 8 (11) Block. The next Attack you play costs 0.'
  },
'Talk to the Hand':{
  text:'Talk to the Hand',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:5,
    exhaust:false,
  },
  cardText:'Deal 5 (7) damage. Whenever you attack this enemy, gain 2 (3) Block. Exhaust.'
  },
'Tantrum':{
  text:'Tantrum',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:3,
    exhaust:false,
  },
  cardText:'Deal 3 damage 3 (4) times. Enter Wrath. Shuffle this card into your draw pile.'
  },
'Wallop':{
  text:'Wallop',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:2,
    class:'Watcher',
    value:9,
    exhaust:false,
  },
  cardText:'Deal 9 (12) damage. Gain Block equal to unblocked damage dealt.'
  },
'Wave of the Hand':{
  text:'Wave of the Hand',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:1,
    exhaust:false,
  },
  cardText:'Whenever you gain Block this turn, apply 1 (2) Weak to ALL enemies.'
  },
'Weave':{
  text:'Weave',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:0,
    class:'Watcher',
    value:4,
    exhaust:false,
  },
  cardText:'Deal 4 (6) damage. Whenever you Scry, return this from the discard pile to your Hand.'
  },
'Wheel Kick':{
  text:'Wheel Kick',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:2,
    class:'Watcher',
    value:15,
    exhaust:false,
  },
  cardText:'Deal 15 (20) damage. Draw 2 cards.'
  },
'Windmill Strike':{
  text:'Windmill Strike',
  cats:{
    type:'Attack',
    rarity:'Uncommon',
    cost:2,
    class:'Watcher',
    value:7,
    exhaust:false,
  },
  cardText:'Retain. Deal 7 (10) damage. When Retained, increase its damage by 4 (5) this combat.'
  },
'Worship':{
  text:'Worship',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:2,
    class:'Watcher',
    value:5,
    exhaust:false,
  },
  cardText:'Gain 5 Mantra.'
  },
'Wreath of Flame':{
  text:'Wreath of Flame',
  cats:{
    type:'Skill',
    rarity:'Uncommon',
    cost:1,
    class:'Watcher',
    value:5,
    exhaust:false,
  },
  cardText:'Your next Attack deals 5 (8) additional damage.'
  },
'Alpha':{
  text:'Alpha',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:1,
    class:'Watcher',
    value:0,
    exhaust:true,
  },
  cardText:'Shuffle a Beta into your draw pile. Exhaust.'
  },
'Blasphemy':{
  text:'Blasphemy',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:1,
    class:'Watcher',
    value:0,
    exhaust:true,
  },
  cardText:'Enter Divinity. Die next turn. Exhaust.'
  },
'Brilliance':{
  text:'Brilliance',
  cats:{
    type:'Attack',
    rarity:'Rare',
    cost:1,
    class:'Watcher',
    value:12,
    exhaust:false,
  },
  cardText:'Deal 12 (16) damage. Deals additional damage equal to Mantra gained this combat.'
  },
'Conjure Blade':{
  text:'Conjure Blade',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:'X',
    class:'Watcher',
    value:0,
    exhaust:true,
  },
  cardText:'Shuffle an Expunger into your draw pile. Exhaust.'
  },
'Deus Ex Machina':{
  text:'Deus Ex Machina',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:'Unplayable',
    class:'Watcher',
    value:2,
    exhaust:true,
  },
  cardText:'Unplayable. When you draw this card, add 2 (3) Miracles to your hand and Exhaust.'
  },
'Deva Form':{
  text:'Deva Form',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:3,
    class:'Watcher',
    value:1,
    exhaust:false,
  },
  cardText:'Ethereal. At the start of your turn, gain [W] and increase this gain by 1.'
  },
'Devotion':{
  text:'Devotion',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:1,
    class:'Watcher',
    value:2,
    exhaust:false,
  },
  cardText:'At the start of your turn, gain 2 (3) Mantra.'
  },
'Establishment':{
  text:'Establishment',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:1,
    class:'Watcher',
    value:1,
    exhaust:false,
  },
  cardText:'Whenever a card is Retained, reduce its cost by 1 this combat.'
  },
'Judgment':{
  text:'Judgment',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:1,
    class:'Watcher',
    value:30,
    exhaust:false,
  },
  cardText:'If the enemy has 30 (40) or less HP, set their HP to 0.'
  },
'Lesson Learned':{
  text:'Lesson Learned',
  cats:{
    type:'Attack',
    rarity:'Rare',
    cost:2,
    class:'Watcher',
    value:10,
    exhaust:true,
  },
  cardText:'Deal 10 (13) damage. If Fatal, Upgrade a random card in your deck. Exhaust.'
  },
'Master Reality':{
  text:'Master Reality',
  cats:{
    type:'Power',
    rarity:'Rare',
    cost:1,
    class:'Watcher',
    value:0,
    exhaust:false,
  },
  cardText:'Whenever a card is created during combat, Upgrade it.'
  },
'Omniscience':{
  text:'Omniscience',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:4,
    class:'Watcher',
    value:0,
    exhaust:true,
  },
  cardText:'Choose a card in your draw pile. Play the chosen card twice and exhaust it. Exhaust.'
  },
'Ragnarok':{
  text:'Ragnarok',
  cats:{
    type:'Attack',
    rarity:'Rare',
    cost:3,
    class:'Watcher',
    value:5,
    exhaust:false,
  },
  cardText:'Deal 5 (6) damage to a random enemy 5 (6) times.'
  },
'Scrawl':{
  text:'Scrawl',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:1,
    class:'Watcher',
    value:0,
    exhaust:true,
  },
  cardText:'Draw cards until your hand is full. Exhaust.'
  },
'Spirit Shield':{
  text:'Spirit Shield',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:2,
    class:'Watcher',
    value:3,
    exhaust:false,
  },
  cardText:'Gain 3 (4) Block for each card in your hand.'
  },
'Vault':{
  text:'Vault',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:3,
    class:'Watcher',
    value:0,
    exhaust:true,
  },
  cardText:'Take an extra turn after this one. End your turn. Exhaust.'
  },
'Wish':{
  text:'Wish',
  cats:{
    type:'Skill',
    rarity:'Rare',
    cost:3,
    class:'Watcher',
    value:6,
    exhaust:true,
  },
  cardText:'Choose one: Gain 6 (8) Plated Armor, 3 (4) Strength, or 25 (30) Gold. Exhaust.'
  },
};
const possible = {...colorless,...ironclad,...silent,...defect,...watcher};   
export default possible;