import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import MainLayout from 'pages/mainLayout';
import ErrorPage from "pages/error-page";

import Home from "pages/Home";
import Privacy from "pages/Privacy";
import Slaydle from "components/slaydle/SlaydleApp";
import Login from "pages/Login";
import Register from "pages/Register";
import ResetPassword from "pages/ResetPassword";

import PortfolioDetails from "components/portfolio/PortfolioDetails";
import Portfolio from "components/portfolio/Portfolio";

import TaskApp from 'components/tasks/TasksApp';
import TaskAppOutlet from 'components/tasks/TaskAppOutlet';

import PrivacySurvivorGame from "pages/PrivacySurvivorGame";

export default function Router (props){
const router = createBrowserRouter([
    {
      path: "/",
      element: <MainLayout/>,
      errorElement: <ErrorPage />,
      children:[
        { index: true, element: <Home /> },
        {
            path: "slaydle",
            element: <Slaydle />,
            children:[
                {
                    path: ":gameDate",
                    element: <Slaydle />,
                },
            ]
        },
        {
            path: "portfolio",
            element: <Portfolio/>,
            children:[
                {
                    path: ":projectId",
                    element: <PortfolioDetails/>
                }
            ],
        },
        {
            path: "tasks",
            element: <TaskAppOutlet />,
            children:[
                { index: true, element: <TaskApp /> },
                {
                    path: ":tasklistId",
                    element: <TaskApp/>,
                    children:[
                        {
                            path: ":taskId",
                            element: <TaskApp/>,
                        }
                    ],
                }
            ],
        },
        {
            path: "login",
            element: <Login />,
        },
        {
            path: "register",
            element: <Register />,
        },
        {
            path: "resetpassword",
            element: <ResetPassword />,
        },
        
        {
            path: "privacy",
            element: <Privacy />,
        },
        
        {
            path: "privacysurvivor",
            element: <PrivacySurvivorGame/>,
        },
        {
            path: "tos",
            element: <Privacy />,
        },
      ]
    },
  ]);
  return (
      <RouterProvider router={router} />
  );
}