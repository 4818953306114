
import React from 'react';
import { connect } from 'react-redux';
import Stack from 'react-bootstrap/Stack';

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton,

    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    WhatsappIcon,
    RedditIcon,
    EmailIcon
  } from "react-share";

const Share = ( {title, text, hashtags} ) => {
    const url = window.location.href;
  

  
  return (
    <Stack className="my-5 mx-auto justify-content-center" direction="horizontal" gap={3}>
        <EmailShareButton url={url} body={text}><EmailIcon size={32} round /></EmailShareButton>
        <FacebookShareButton url={url} quote={text}><FacebookIcon size={32} round /></FacebookShareButton>
        <LinkedinShareButton url={url} title={title} ><LinkedinIcon size={32} round /></LinkedinShareButton>
        <RedditShareButton url={url} title={title} ><RedditIcon size={32} round /></RedditShareButton>
        <TwitterShareButton url={url} title={title} hashtags={hashtags} ><TwitterIcon size={32} round /></TwitterShareButton>
        <WhatsappShareButton url={url} title={title}><WhatsappIcon size={32} round /></WhatsappShareButton>
    </Stack>
  );
  
}



const mapStateToProps = (state) => {
  return {
    
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Share);

