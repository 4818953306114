import { ref, update, onValue, push, remove, child, set } from "firebase/database";
import {db} from "components/auth/firebase";
import { objectMap } from "helpers/Helpers";
export function personalTasklistsFetchDataSuccess(tasklists) {
    console.log("ptasklists save:",tasklists);
    return {
        type: 'PERSONAL_TASKLISTS_FETCH_DATA_SUCCESS',
        tasklists
    };
}

export function collaboratorTasklistsFetchDataSuccess(tasklists) {
    console.log("ctasklists save:",tasklists);
    return {
        type: 'COLLABORATOR_TASKLISTS_FETCH_DATA_SUCCESS',
        tasklists
    };
}
export function tasklistFetchDataSuccess(tasklist) {
    console.log("tasklist save:",tasklist);
    return {
        type: 'TASKLIST_FETCH_DATA_SUCCESS',
        tasklist
    };
}

export function taskFetchDataSuccess(task) {
    console.log("task save:",task);
    return {
        type: 'TASK_FETCH_DATA_SUCCESS',
        task
    };
}

let unsubscribeHandles = {};
export function unsubscribeObserver(reference){
    const unsubHandle = unsubscribeHandles[reference];
    if(unsubHandle!==undefined){
        console.log("unsub Task Handle", reference);
        unsubHandle();
    }
}
export function initiatePersonalTaskLists(userId){
    
    return (dispatch) => {
        const path = '/users/'+userId+'/tasklists/';
        unsubscribeHandles['tasklists/'+userId] = onValue(ref(db,path),(snapshot)=>{
            let valuesArr = snapshot.val();
            dispatch(personalTasklistsFetchDataSuccess(valuesArr));
        },
        (error)=>{
            console.log("personal tasklists Snapshot error",error);
        });
    }
}

export function initiateCollaboratorTasklists(emailAddress){
    
    return (dispatch) => {
        if(!emailAddress){return;}
        const normalizedEmail = emailAddress.replace(/\@|\./g,'!');
        const collaboratorPath = '/tasklistCollaborators/'+normalizedEmail+'/';
        
        unsubscribeHandles['collaboratorTasklists/'+emailAddress] = onValue(ref(db,collaboratorPath),(snapshot)=>{
            let valuesArr = snapshot.val();
            dispatch(collaboratorTasklistsFetchDataSuccess(valuesArr));
        },
        (error)=>{
            console.log("Collaborator tasklists Snapshot error",error);
        });
    }
}
export function initiateTasklist(tasklistId){
    
    return (dispatch) => {
        const path = '/tasklists/'+tasklistId;
        unsubscribeHandles['tasklist/'+tasklistId] = onValue(ref(db,path),(snapshot)=>{
            let valuesArr = snapshot.val();
            dispatch(tasklistFetchDataSuccess(valuesArr));
        },
        (error)=>{
            console.log("personal tasklist Snapshot error",error);
        });
    }
}


export function initiateTask(taskId){
    
    return (dispatch) => {
        const path = '/tasks/'+taskId;
        unsubscribeHandles['task/'+taskId] = onValue(ref(db,path),(snapshot)=>{
            let valuesArr = snapshot.val();
            dispatch(taskFetchDataSuccess(valuesArr));
        },
        (error)=>{
            console.log("task Snapshot error",error);
        });
    }
}

export function newTaskList(uid,title, callBack){
    return (dispatch) => {
        console.log("new tasklist",uid);
        console.log("new tasklist",title);
        const path = '/tasklists/';
        const newKey = push(child(ref(db),path)).key;
        const newPath = path +"/"+newKey;
        
        const attributes = {
            title:title
        }
        const newList = {
            userId:uid,
            tasklistId:newKey,
            ...attributes
        };
        set(ref(db,newPath),newList);

        // save reference in user
        const userPath = '/users/'+uid+'/tasklists/'+newKey;
        set(ref(db,userPath), attributes);

        callBack(newList);
    }
}

export function updateTaskList(tasklist, tasklistId,updateObj){
    return (dispatch) => {
        console.log("update tasklist",updateObj);
        const {userId,collaborators} = tasklist;
        

        const attributes = {
            ...updateObj
            // title:updateObj.title
        }
        
        const path = '/tasklists/'+tasklistId;
        
        
        update(ref(db,path),attributes);

        // save reference in user
        if(userId){
            const userPath = '/users/'+userId+'/tasklists/'+tasklistId;
            update(ref(db,userPath), attributes);
        }

        //update each tasklistCollaborator object
        if(collaborators){

            const collabBasePath = '/tasklistCollaborators/';
            objectMap(collaborators,(emailAddress,k)=>{
                const normalizedEmail = emailAddress.replace(/\@|\./g,'!');
                const collabPath = collabBasePath + normalizedEmail+'/'+tasklistId;
                update(ref(db,collabPath),attributes);
            });
        }
    }
}

export function updateTaskListOrder(tasklistId, newList){
    return (dispatch) => {
        console.log("update tasklist Order ",newList);
        
        const path = '/tasklists/'+tasklistId+"/customOrder/";
        set(ref(db,path),newList);
    }
}

export function updateBoardItemOrder(tasklistId, newList){
    return (dispatch) => {
        console.log("update board item Order ",newList);
        
        const path = '/tasklists/'+tasklistId+"/boardview/columns/";
        set(ref(db,path),newList);
    }
}
export function updateBoardOrder(tasklistId, newList){
    return (dispatch) => {
        console.log("update board Order ",newList);
        
        const path = '/tasklists/'+tasklistId+"/boardview/columnOrder/";
        set(ref(db,path),newList);
    }
}

export function addBoardColumn(tasklistId){
    return (dispatch) => {
        
        
        const newCol = {
            title:"New Column",
            keys:[],
        };
        const colPath = '/tasklists/'+tasklistId+"/boardview/columns/";
        const newKey = push(ref(db,colPath),newCol).key;

        const path = '/tasklists/'+tasklistId+"/boardview/columnOrder/";
        push(ref(db,path),newKey);
    }
}


export function addCollaborator (tasklistId, dataObj){
    return (dispatch) => {
        console.log(tasklistId);
        console.log(dataObj);
        const {emailAddress,title} = dataObj;
        const path = '/tasklists/'+tasklistId + '/collaborators/';
        push(ref(db,path), emailAddress);

        const normalizedEmail = emailAddress.replace(/\@|\./g,'!');
        const collaboratorPath = '/tasklistCollaborators/'+normalizedEmail+'/'+tasklistId;
        set(ref(db,collaboratorPath),{title:title});
    }
}
export function removeCollaborator (tasklistId, dataObj){
    return (dispatch) => {
        console.log(tasklistId);
        console.log(dataObj);
        const {collaboratorId,emailAddress} = dataObj;
        const path = '/tasklists/'+tasklistId + '/collaborators/'+collaboratorId;
        remove(ref(db,path));


        const normalizedEmail = emailAddress.replace(/\@|\./g,'!')
        const collaboratorPath = '/tasklistCollaborators/'+normalizedEmail+'/'+tasklistId;
        remove(ref(db,collaboratorPath),true);

    }
}



export function addPTask(uid,tasklistId,taskObj){
    return (dispatch) => {
        const path = '/tasks/';
        const myRef = ref(db);
        const newKey = push(child(myRef,path)).key;
        const newPath = path + "/"+newKey;
        console.log("new ptask");

        const tasklistPath = '/tasklists/'+tasklistId+"/tasks/"+newKey;
        const userPath = '/users/'+uid+'/tasks/'+newKey;
        
        const newTask = {
            ...taskObj,
            done:false,
            
            tasklistId: tasklistId,
            userId:uid,
            taskId:newKey,

            path:newPath,
            tasklistPath:tasklistPath,
            userPath:userPath,
            urlPath: '/tasks/'+tasklistId + "/"+newKey,
        };
        set(ref(db,newPath),newTask);
        // save copy in tasklist
        
        set(ref(db,tasklistPath),newTask);
        // save copy in user/tasks
        
        // set(ref(db,userPath), newTask);
    }
}




export function deletePTask(task){
    return (dispatch) => {
        console.log("delete ptask");
        
        remove(ref(db,task.path));
        remove(ref(db,task.tasklistPath));
        remove(ref(db,task.userPath));
    }
}


export function updatePTask(task, taskObj){
    return (dispatch) => {
        console.log("update task",taskObj);
        const newTask = {
            ...taskObj
        };
        update(ref(db,task.path),newTask);
        update(ref(db,task.tasklistPath),newTask);
        // update(ref(db,task.userPath),newTask);
    }
}

