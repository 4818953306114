import { connect } from 'react-redux';
import AuthUI from './authUI';

import {Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {logOut} from './firebase';
import EmailSignin from './EmailSignin';
import GoogleSignin from './GoogleSignin';
import ResetPasswordLink from './ResetPasswordLink';
import RegisterLink from './RegisterLink';
import LoginLink from './LoginLink';
import EmailRegister from './EmailRegister';
import ResetPassword from './ResetPassword';
import './auth.scss';
/** 
 * available props:
 * displayName - Nav Text
 * logout - Nav Link
 * login - Nav Link
 * authUI - deprecated
 * resetPasswordLink
 * registerLink
 * loginLink
*/
const AuthWidgets = function (props){

   
    if(props.user && props.user.isAnonymous ===false){
        // const uid = props.user.uid;
        const displayName = props.user.displayName;
        // const email = props.user.email;
        // const photoURL = props.user.photoURL;
        // const emailVerified = props.user.emailVerified;
        // User Logged In.
        return (<>
            {props.displayName?<Nav.Link>{displayName}</Nav.Link>:<></>}
            {props.logout?<Nav.Link className="logout-button" 
                onClick={()=>{
                    logOut();
                }}
            >Logout</Nav.Link>:<></>}
        </>);
    }
    else {
        //Anon or not logged in.
        return (
            <>
            {props.login?<LinkContainer to="/login"><Nav.Link className="login-button">Login</Nav.Link></LinkContainer>:<></>}
            {props.authUI?<AuthUI/>:<></>}
            {props.googleSignin?<GoogleSignin/>:<></>}
            {props.emailSignin?<EmailSignin/>:<></>}
            {props.resetPasswordLink?<ResetPasswordLink/>:<></>}
            {props.registerLink?<RegisterLink/>:<></>}
            {props.loginLink?<LoginLink/>:<></>}
            {props.emailRegister?<EmailRegister/>:<></>}
            {props.resetPassword?<ResetPassword/>:<></>}
            

            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
  };
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
       
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AuthWidgets);
  
  