import { initializeApp } from "firebase/app";
import firebaseConfig from './firebaseConfig';
import { getAuth,onAuthStateChanged  } from "firebase/auth";
import {setUser} from "./actions";
import { connect } from 'react-redux';


import signInAnon from './anonAuth';


import {useEffect} from 'react';


  
  // Use this to initialize the firebase App
const firebaseApp = initializeApp(firebaseConfig);
if(firebaseApp){}
let authStateInitiated = false;
const Auth = function (props){


    const auth = getAuth();
    
    useEffect(() => {
        if(authStateInitiated===false){
            authStateInitiated = true;
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/auth.user
                    props.setUser(user);
                    console.log('logged in user',user);
                    
                } else {
                    console.log('no user');
                    props.setUser(null);
                    // User is signed out
                    //sign in as an Anon 
                    signInAnon();
                    
                }
            });
            
        }
    },[auth, props]);
    if(props.user && props.user.isAnonymous ===false){
        // User Logged In. and not anon
    }
    
    return (
        <></>
    );
    
}


const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
  };
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
        setUser:(user)=>{ dispatch(setUser(user))},
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(Auth);
  
  