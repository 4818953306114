import {signInWithGoogle} from './firebase';

const GoogleLogin = ()=>{
  const img_src = "https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg";

  return (<>
  <button className="login__btn login__google google-btn" onClick={signInWithGoogle}>
    <div className="google-icon-wrapper">
      <img className="google-icon" alt="google icon" src={img_src}/>
    </div>
    <p className="btn-text">Login with Google</p>
  </button>
  </>);
}

export default GoogleLogin;