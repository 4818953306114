const categoryNames = [
    {
      name:"cost",
      type:"numeric",
    },
    {
      name:"type",
      type:"text",
    },
    {
      name:"value",
      type:"numeric",
    },
    {
      name:"rarity",
      type:"text",
    },
    {
      name:"class",
      type:"text",
    },
    {
      name:"exhaust",
      type:"boolean",
    },
  ];

  const title = "Slaydle";
  const path = "/slaydle/";
  const gameSlug = "slaydle";
  const maxGuesses = 100;


export {categoryNames, title, path,maxGuesses, gameSlug};