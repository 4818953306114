import {Link} from 'react-router-dom';
const isExternalLink=(to)=>{
    if(to.toString().startsWith('http') || to.toString().startsWith('mailto') || to.toString().startsWith('tel')) {
        return true;
    }
}

const LinkWrapper = ({children, to})=>{
    if(isExternalLink(to)){
        return (
            <a href={to} rel="noreferrer" target="_blank">{children}</a>
            );
    } else { //is internal
        return (
            <Link to={to}>
                {children}
            </Link>
        );
    }
}
const Validate = {
    email:(email)=>{
        const pattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        return pattern.test(email);
    }
}

const getFormattedDate=(newDate, separator='-')=>{
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;
}
const objectMap = (obj, fn) =>{
    return Object.entries(obj).map(
        ([k, v], i) =>  fn(v, k, i)
    );
}


export {isExternalLink, LinkWrapper, Validate, getFormattedDate,objectMap};
