


import { Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import { objectMap } from 'helpers/Helpers';

const ListMenu = ({lists={},path})=>{
    
    let itemsOutput;
    if(lists){
        itemsOutput = objectMap(lists,(value,key)=>(

            <LinkContainer key={key} to={path+key}>
                <Nav.Link>{value.title}</Nav.Link>
            </LinkContainer>
        ));
    }
    
    return (
        <div>
        
            <Nav  className="flex-column">
                {itemsOutput}
            </Nav>
        
        </div>
    );
}


export default ListMenu;