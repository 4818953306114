import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebaseui/dist/firebaseui.css'
import { useEffect,useMemo  } from 'react'

import { getDatabase,ref, set,onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import firebaseConfig from './firebaseConfig';

import {setUser} from "./actions";
import { connect } from 'react-redux';
var firebaseui = require('firebaseui');
// assumes not logged in. use props in auth.js to render this or not.
// Component to show Login UI.  

const AuthUI =(props)=>{
    // Initialize the FirebaseUI Widget using Firebase.
    var ui = firebaseui.auth.AuthUI.getInstance();
    if(!ui){
        ui = new firebaseui.auth.AuthUI(firebase.auth());
    }
    
    const uiConfig = useMemo(()=>{return{
        autoUpgradeAnonymousUsers: true,
        callbacks: {
            signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            // User successfully signed in.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.  returning false means no redirect.
            return false;
            },
            uiShown: function() {
            // The widget is rendered.
            // Hide the loader.
               document.getElementById('loader').style.display = 'none';
            },

            signInFailure: function(error) {
                // For merge conflicts, the error.code will be
                // 'firebaseui/anonymous-upgrade-merge-conflict'.
                if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
                  return Promise.resolve();
                }
                var data = null;
                // Hold a reference to the anonymous current user.
                var anonymousUser = firebase.auth().currentUser;
                // The credential the user tried to sign in with.
                var cred = error.credential;
                // Copy data from anonymous user to permanent user and delete anonymous
                // user.
                var app = initializeApp(firebaseConfig);
                const db = getDatabase(app);
                const userId=anonymousUser.uid;

                console.log('anon user',anonymousUser);
                console.log('cred user ',cred);
                
                return onValue(ref(db, 'users/' + userId), (snapshot) => {
                    data = snapshot.val();
                    console.log('anon user db',data);
                    return firebase.auth().signInWithCredential(cred).then(()=>{
                        return set(ref(db,'users/' + cred.uid), data);
                    }).then(function() {
                        console.log('anon user delete',anonymousUser);
                        // Delete anonymnous user.
                        console.log('anon user delete',anonymousUser);
                        return anonymousUser.delete();
                    }).then(function() {
                        // Clear data in case a new user signs in, and the state change
                        // triggers.
                        data = null;
                        // FirebaseUI will reset and the UI cleared when this promise
                        // resolves.
                        // signInSuccessWithAuthResult will not run. Successful sign-in
                        // logic has to be run explicitly.
                        //  window.location.assign('/');
                        props.setUser( firebase.auth().currentUser);
                        console.log('state user',props.state.user);
                    });;
                    
                  }, {
                    onlyOnce: true
                  });
            }
        },
        // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
        signInFlow: 'popup',
        signInSuccessUrl: '/',
        signInOptions: [
            // Leave the lines as is for the providers you want to offer your users.
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
            // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
            // firebase.auth.GithubAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            // firebase.auth.PhoneAuthProvider.PROVIDER_ID
        ],
        // Terms of service url.
        tosUrl: '/tos',
        // Privacy policy url.
        privacyPolicyUrl: '/privacy'
    }},[props]);
    useEffect(()=>{
        console.log('useEffect-authUI');
        ui.reset();
        if (!ui.isPendingRedirect()) {
            console.log('authUI-start');
            ui.start('#firebaseui-auth-container', uiConfig);
        }    
    },[ui,uiConfig]);
    
    return (
        <div className="authUi">
            <div id="firebaseui-auth-container"></div>
            <div id="loader"></div>
        </div>
    );
    



}


const mapStateToProps = (state) => {
    return {
        user: state.user,
    };
  };
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
        setUser:(user)=>{ dispatch(setUser(user))},
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AuthUI);
  
  