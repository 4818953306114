
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {deletePTask,updatePTask} from './actions/tasks';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'components/ui/forms/Input';
import {BiTrash, BiEdit, BiSave, BiX} from 'react-icons/bi';

import { 


} from './actions/tasks';

const Task = ({task,deleteTask,updateTask})=>  {
  
  const [editText, setEditText] = useState(task.title); 
  const [editNumber, setEditNumber] = useState(task.number); 
  const [isEditing, setIsEditing] = useState(false); 
  const navigate = useNavigate();
  let numberContent = null;
  if(task.type==="number"){
    numberContent = (
      <Col xs={2}>
        <Input 
        hasLabel={false}
        type="number"
        value={editNumber}
        onChange={(val)=>setEditNumber(val)}
        onBlur={(val)=>{
          updateTask(
            task,
            {
              number: editNumber
            });
          }}
          
          
          />
      </Col>
    );
  }
  

  
  let taskContent;
  let taskButtons;
  if (isEditing===true ) {
    taskContent = (
      <>
      {numberContent}
      <Col>
        <Input
          hasLabel={false}
          value={editText}
          onChange={(e)=>setEditText(e.target.value)} />
      </Col>
      </>
      );
      taskButtons = (
        <>
        <Button 
          size="sm"
          onClick={(e) => {
            setIsEditing(false);
            updateTask(
              task,
              {
                title: editText
              });
          }}
        >
          <BiSave/> Save
        </Button>
        <Button 
          size="sm"
          onClick={(e) => {
            setIsEditing(false);
            setEditText(task.title);
          }}
        >
          <BiX aria-label="Cancel"/>
        </Button>
      </>
    );
  } else {
    taskContent = (
      <>
        {numberContent}
        <Col >
          {task.title}
        </Col>
        {task.dueDate&&<Col>{task.dueDate}</Col>}
      </>);
    taskButtons = (
        <>
        <Button 
          size="sm"
        onClick={() => {
          setIsEditing(true);
        }}>
          Quick Edit
        </Button>
        <Button 
        size="sm"
         onClick={() => {
          navigate(task.urlPath);
        }}>
          <BiEdit aria-label='Edit'/>
        </Button>
      </>
    );
  }
  return (
    <Row>
      

      <Col xs={1}>
      <input
        type="checkbox"
        checked={task.done}
        onChange={e => {
          updateTask(task,{
            done: e.target.checked
          });
        }}
        />
      </Col>
      {taskContent}

    <Col xs="auto">
      {taskButtons}
      <Button 
      size="sm"
      onClick={() => {
        deleteTask(task);
      }}>
        <BiTrash aria-label="Delete Task"/>
        
      </Button>
    </Col>
    </Row>
  );

}



Task.propTypes = {
  deleteTask: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired
};


const mapStateToPropsTask = (state) => {
  return {
  };
};


const mapDispatchToPropsTask = (dispatch) => {
  return {
    deleteTask: (task) => dispatch(deletePTask(task)),
    updateTask: (task, newTask) => dispatch(updatePTask(task, newTask)),


  };
};

export default connect(mapStateToPropsTask, mapDispatchToPropsTask)(Task);
