
import React from 'react';
import { useState } from 'react';
import { connect } from 'react-redux';
import Popup from './Popup';
import Button from 'react-bootstrap/Button';
const InfoPopup = ({children, title="How to Play"}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  return (
    <>
        <Button variant="info"  onClick={handleShow}>How to Play</Button>
        <Popup title={title} show={show} handleClose={handleClose}>
            {children}
        </Popup>
    </>
  );
  
}



const mapStateToProps = (state) => {
  return {
    
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoPopup);

