import BootstrapInput from 'react-bootstrap/FormControl';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const Input = (props)=>{
    const {
        field,
        label,
        value="",
        onChange=()=>{},
        onEnter=()=>{},
        onBlur=()=>{},
        type=false,
        hasLabel = true,
        
    } = props;

    const input = (
        <BootstrapInput
            type={type}
            value={value}
            onChange={(e)=>onChange(e.target.value)} 
            onKeyDown={(e)=>{
                if(e.key==='Enter' && onEnter){
                    onEnter();
                }
            }}
            onBlur={(e)=>onBlur(e.target.value)} 
        />
    );

    if(hasLabel){
        return (
        <FloatingLabel
            controlId={"floating-"+field}
            label={label}
            className="mb-3"
        >
            {input}    
        </FloatingLabel>
      );
    } 

    return (<>
        {input}
    </>);
}

export default Input;