import { initializeApp } from "firebase/app";
import {GoogleAuthProvider,
getAuth,
signInWithEmailAndPassword,
sendPasswordResetEmail,
signOut,
signInWithCredential,
linkWithCredential,
EmailAuthProvider,
linkWithPopup,
} from "firebase/auth";


import { getDatabase } from "firebase/database";
import firebaseConfig from './firebaseConfig';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getDatabase(app);
const googleProvider = new GoogleAuthProvider();



const signInWithGoogle = async () => {
    try {
        
        //try linking credential. upgrades anon account
        await linkWithPopup(auth.currentUser, googleProvider);
            
    } catch (err) {
        if(err.code==="auth/credential-already-in-use"){
            // credential already has login.
            var credential = GoogleAuthProvider.credentialFromError(err);
            // just sign into account. discard anonymous user data.
            signInWithCredential(auth, credential).then((userCred)=>{
                console.log("signed in user", userCred.user);
            });
            
            
        } else {
            console.log(err.code);
            console.log(err.message);
            
        }
    }
};
const logInWithEmailAndPassword = async (email, password) => {
    try {
        
        await signInWithEmailAndPassword(auth, email, password);
        return false;
    } catch (err) {
        if(err.code==="auth/user-not-found"){
            return "Invalid Username or Password.";
        }
        if(err.code==="auth/wrong-password"){
            return "Invalid Username or Password.";
        }
        return err.code;
    }
};
const registerWithEmailAndPassword = async (name, email, password) => {
    let authCredential;
    try {
        authCredential = EmailAuthProvider.credential(email, password);
        await linkWithCredential(auth.currentUser, authCredential);
        return false;
    } catch (err) {
        console.error(err);
        if(err.code ==="auth/provider-already-linked"){
            await signInWithCredential(auth, authCredential);
        }
        if(err.code ==="auth/weak-password"){
            return "Password too weak.";
        }
        return err.message;
    }
};
const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        return "Password reset link sent!";
    } catch (err) {
        console.error(err);
        return err;
        // alert(err.message);
    }
};
const logOut = () => {
    signOut(auth);
};
export {
    auth,
    db,
    signInWithGoogle,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logOut,
};

