import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import {LinkWrapper} from 'helpers/Helpers';

const PortfolioDetails = (props)=>{
    const url = props.url;
    const title = props.title;
    const description = props.description;
    const skills = props.skills;
    const img = props.image;
    
    const linkToProject = (
        <LinkWrapper to={url}>{url}</LinkWrapper>
    );
        
    const skillsBlock = (
        <Stack direction="horizontal" gap={2}>
            {skills.map((skill)=>(
                <Badge key={skill} pill bg="primary">{skill}</Badge>
            ))}
        </Stack>
    );
    const header = (
        <div>
            <div>{title}</div>
            {skillsBlock}
            {linkToProject}
        </div>
    );

    const details = (
        <div >
            {img}
            
            
            
            <div>{description}</div>
                
        </div>
    );

    return (
        <>
        {header}
        {details}
        </>
    );
    
}

export default PortfolioDetails;
