
import { Outlet } from 'react-router-dom';
import ListMenu from './ListMenu';
import AddTasklist from './AddTasklist';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import {connect} from 'react-redux';
import {useEffect} from 'react';
import {
  initiatePersonalTaskLists,
  initiateCollaboratorTasklists,
  unsubscribeObserver, 
  newTaskList, 
  
} from './actions/tasks';



var oldUserId=undefined;

const TaskAppOutlet=({
  myInitiatePersonalTasklists,
  myInitiateCollaboratorTasklists,
  user,
  personalTasklists,
  collaboratorTasklists
})=> {
  

  const path = '/tasks/';
  let userId,email;
  if(user){
    userId = user.uid;
    email = user.email;
  }
  
  
  useEffect(()=>{
    console.log('useeffect uid taskapp outlet');  
    if(userId !==oldUserId){
      if(oldUserId !==undefined){
        unsubscribeObserver('tasklists/'+oldUserId);
      }
      myInitiatePersonalTasklists(userId);
      myInitiateCollaboratorTasklists(email);
      oldUserId = userId;
    }
    
    return ()=>{

      unsubscribeObserver('tasklists/'+oldUserId); 
      oldUserId = undefined;
    }
  },[myInitiatePersonalTasklists,myInitiateCollaboratorTasklists,userId]);

  
  if(userId == null||userId == undefined || userId !== oldUserId){
    return null;
  }
    
  
  
  return (
    <>
    
    <Container fluid>
        <Row>
            <Col xs={12} md={4} lg={3} className="tasklist-sidebar" >
                <Container fluid>
            
                    <Row>
                        <Col xs={12}>
                        <h3>Personal Tasklists</h3>
                        </Col>
                    </Row>
                        <AddTasklist path={path} />
                    <Row>
                        <Col  xs={12}>
                        <ListMenu path={path} lists={personalTasklists}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col  xs={12}>
                        <h3>Collaborator Tasklists</h3>
                        <ListMenu path={path} lists={collaboratorTasklists}/>
                        </Col>
                    </Row>
                </Container>
    
            </Col>
            <Col xs={12} md={8} lg={9}>
                <Outlet/>
            </Col>
        </Row>
    </Container>
    </>
  );
}



const mapStateToProps = (state) => {
  return {
      
      personalTasklists: state.personalTasklists,
      collaboratorTasklists: state.collaboratorTasklists,
      user:state.user,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    
    myInitiatePersonalTasklists: (userId)=>dispatch(initiatePersonalTaskLists(userId)),
    myInitiateCollaboratorTasklists: (userId)=>dispatch(initiateCollaboratorTasklists(userId)),
    myNewTaskList: (uid,title)=>dispatch(newTaskList(uid,title)),
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskAppOutlet);

