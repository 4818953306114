
import React, { useState } from "react";
import {useNavigate} from "react-router-dom";
import {
  registerWithEmailAndPassword,
} from "./firebase";


import Button from 'react-bootstrap/Button';
import Input from 'react-bootstrap/FormControl';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {toast} from "react-toastify";
import {Validate} from "helpers/Helpers";


const EmailRegister = (props)=>{
  const navigate = useNavigate();
  const [ form, setForm ] = useState({});
  const { name, email,password } = form;
  const [errors, setErrors] = useState({});
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });
    if ( !!errors[field] ) {
      setErrors({
        ...errors,
        [field]: null
      });
    }
  };
  const findFormErrors = () => {
    
    const newErrors = {}
    // name errors
    if ( !name || name === '' ) newErrors.name = 'cannot be blank!'
    else if ( name.length > 30 ) newErrors.name = 'name is too long!'
    
    // email errors
    if ( !email || email === '' ) newErrors.email = 'Email cannot be blank!'
    else if ( email.length > 30 ){ newErrors.email = 'Email is too long!'}
    else if (!Validate.email(email) ){ newErrors.email = 'Invalid Email'}
    
    // password errors
    if ( !password || password === '' ) {newErrors.password = 'Password cannot be blank!';}
    else if ( password.length < 6 ){ newErrors.password = 'Minimum password length of 6!';}
    // else if ( password. < 6 ) {newErrors.password = 'Minimum password length of 6!';}
    
    return newErrors
  }
   

    const handleSubmit = (event) => {
      event.preventDefault();
      
      if(validate()){
        // No errors! Put any logic here for the form submission!
        register();
      }
      
    };
  const validate = ()=>{
    const newErrors = findFormErrors();
    setErrors(newErrors);
    if ( Object.keys(newErrors).length > 0 ) {
      // We got errors!
      return false;
    } else {
      // No errors!
      return true;
    }
  }
  
  const register = async () => {
    
    const error = await registerWithEmailAndPassword(name, email, password);
    if(error){
      setErrors(
      {
        submitError: error
      });
      toast(error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    } else {    
      toast('Logged In!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      navigate("/");
    }
      
  };
    
  return (
    <div className="narrow">
    <Form noValidate onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Group>
            <FloatingLabel
              controlId="floatingEmail"
              label="Display Name"
              className="mb-3"
            >
              <Input
                type="text"
                className="register__textBox"
                value={name}
                onChange={(e) => setField("name",e.target.value)}
                onBlur={(e)=>validate()}
                placeholder="Display Name"
                required
                isInvalid={ !!errors.name }
              />
            </FloatingLabel>
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col >
          <Form.Group>
            <FloatingLabel
              controlId="floatingEmail"
              label="Email address"
              className="mb-3"
            >
              <Input
                type="email"
                className="register__textBox"
                value={email}
                onChange={(e) => setField("email",e.target.value)}
                onBlur={(e)=>validate()}
                placeholder="E-mail Address"
                required
                isInvalid={ !!errors.email }
              />
              </FloatingLabel>
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col >
          <Form.Group >
            <FloatingLabel
              controlId="floatingPassword"
              label="Password"
              className="mb-3"
            >
              <Input
                type="password"
                className="register__textBox"
                value={password}
                onChange={(e) => setField("password",e.target.value)}
                onBlur={(e)=>validate()}
                placeholder="Password"
                required
                isInvalid={ !!errors.password }
              />
            </FloatingLabel>
          </Form.Group>
          
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Col>
        <Col xs={2}>
          <Button type="submit" className="register__btn" >
            Register
          </Button>
        </Col>
      </Row>
    </Form>
    </div>);
        
}

export default EmailRegister;