import Input from 'react-bootstrap/FormControl';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {getFormattedDate} from 'helpers/Helpers';

import {forwardRef} from 'react';

const StdDatePicker = (props)=>{
    const {
        field,
        label,
        value,
        onChange,
    } = props;
    
    const selectedDate = value? new Date(value+' 12:00:00'): null;
    
    const DatepickerInput = forwardRef(({ value, onClick }, ref) =>(
            <FloatingLabel
                controlId={"floating-"+field}
                label={label}
                className="mb-3"
            >
                <Input
                    value={value}
                    // onChange={(e)=>onChange({field},e.target.value)} 
                    onClick={onClick}
                    ref={ref}
                    readOnly
                    />
            </FloatingLabel>
    ));
    return (
        <>

            <DatePicker 
                todayButton="Today" 
                dateFormat="yyyy-MM-dd" 
                selected={selectedDate} 
                customInput={<DatepickerInput/>}
                onChange={(newDate)=>{
                    const formattedDate = getFormattedDate(newDate);
                    onChange(formattedDate);
                }} 
            />
</>
  );
}

export { StdDatePicker };