import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";

import { auth, sendPasswordReset } from "./firebase";

import Button from 'react-bootstrap/Button';
import Input from 'react-bootstrap/FormControl';
// import "./Reset.css";
const ResetPassword = ()=> {
  const [email, setEmail] = useState("");
  const [user, loading, error] = useAuthState(auth);
  if(error){}
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) return;
    if (user&& !user.isAnonymous) navigate("/");
  }, [user, loading,navigate]);
  return (
      <>
    
        <Input
          type="email"
          className="reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <Button
          className="reset__btn"
          onClick={() => sendPasswordReset(email)}
        >
            Send password reset email
        </Button>
       </>
  );
}
export default ResetPassword;