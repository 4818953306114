
import React, {  } from 'react';
import { connect } from 'react-redux';
import {addPTask} from './actions/tasks';
import AddItem from 'components/ui/forms/AddItem';

const AddTask = (props)=> {

  const uid = props.user.uid;
  const tasklistId = props.tasklistId;
  
  const submitAddTask=(obj)=>{
    if(uid && tasklistId){
          
      props.addTask(
        uid,
        tasklistId,
        {
          title: obj.title,
          done:false
        }
      );
    }
  }
  

  

    
  return (
      <AddItem 
        label="Task Title"
        buttonText="Add New Task"
        submitFunction={(val)=>submitAddTask(val)} />
    
  );
  
}



const mapStateToProps = (state) => {
  return {
    user:state.user,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    addTask: (uid,tasklistId,newItem) => dispatch(addPTask(uid,tasklistId,newItem)),  
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTask);

