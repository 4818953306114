import React, {useState} from 'react';
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { Button } from 'react-bootstrap';

import { BiPlusCircle } from 'react-icons/bi';

import { useSortable } from '@dnd-kit/sortable';
import {SortableItem} from '../SortableItem';
import SortableColumn from './SortableColumn';

const MultiColumnSortableArea=({onRender, onDrop, onItemDrop, onNewBoardColumn,
    propColumns
    ={
      "col1key":  {
            title:"first",
            keys:[
                '-Nc8x_lzSGJ55X19Ld0k', 
                '-Nc8xWjO2JdTGFgq6ewb', 
                '-Nc90nhSWgQZjpq0h5i8', 
                '-Nc-_6xvQ4Sz_YEFnQZM', 

            ],
        },
      "col2key":  {
            title:"second",
            keys:[
          
                '-Nc90mq2Agqx4wvIyzcM', 
                '-NcxxzpXcaXs_-UvQM1y', 
                '-Ncxs5vIgPgHLJ0lQhHE', 
                '-Ncxy17drvYDKAZV9KJ7',
            ],
        }
      }
    ,
    // keys=[], 
  hasHandle=false,
  propColumnOrder={
    col1:"col1key",col2:"col2key"
  },
  
}) =>{

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const [columnOrder,setColumnOrder] = useState(Object.values(propColumnOrder));
  const [columns,setColumns] = useState(propColumns);
  const [activeId, setActiveId] = useState();
  const [activeType, setActiveType] = useState();

  console.log("propcolumns",columns);
  console.log("colOrder",columnOrder);
  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
        onDragOver={handleDragOver}
    >
      <SortableContext 
            items={columnOrder}
            strategy={horizontalListSortingStrategy}
            >

        {columnOrder.map((key,ind)=>{
          if(!columns[key]){return;}
          return (<SortableColumn key={key} id={key} title={columns[key].title} keys={columns[key].keys} onRender={onRender} />)
        })}

        <Button onClick={onNewBoardColumn} className="new-column"><BiPlusCircle/></Button>
      </SortableContext>

      <DragOverlay>{activeId && activeType==="item"?<>{onRender(activeId)}</>  : activeId && activeType==="column"?<><SortableColumn id={activeId} title={columns[activeId].title} keys={columns[activeId].keys} onRender={onRender} /></>:null }</DragOverlay>
    </DndContext>
  );


  function handleDragStart(event) {
    const { active } = event;
    const { id } = active;
    const type = active.data.current.type;
    setActiveId(id);
    setActiveType(type);
  }

  
  function handleDragOver(event) {
    const { active, over, draggingRect } = event;
    if (!active|| !over|| active.id == over.id) { return;}
    const { id } = active;
    const { id: overId } = over;
    // console.log("drag over", event);

  
    const activeData = active.data.current;
    const overData = over.data.current;
    if(activeData.type==="column"){
      const newColumnOrder = handleColumnDrag(event, activeData,overData);
      console.log("new col order",newColumnOrder);
      setColumnOrder(newColumnOrder);
      
    }
    else if(activeData.type ==="item"){
      const newColumns = handleItemDrag(event, activeData,overData);
      console.log("new columns",newColumns);
      setColumns(newColumns);
      
    }
    
  }
  function handleColumnDrag(event,activeData,overData){
    console.log("column event",event);
    const {active, over} = event;
    let overColId;
    if (overData.type==="item"){ // drop on item - rearrange
      overColId = overData.parent;
    }
    else { // drop on column, 
      overColId = over.id;
    }
    
    const oldIndex = columnOrder.indexOf(active.id);
    const newIndex = columnOrder.indexOf(overColId);
    const newOrderKeys = arrayMove(columnOrder, oldIndex, newIndex);
    return newOrderKeys;
    
  }

  function handleItemDrag(event, activeData,overData){
    console.log("item event",event);
    const {active, over} = event;
    let overColId;
    let overIndex;

    if (overData.type==="item"){ // drop on item - rearrange
      overColId = overData.parent;
    }
    else { // drop on column, 
      overColId = over.id;
      overIndex = columns[overColId].keys ?columns[overColId].keys.length :0;
    }

    let activeColumn = columns[activeData.parent];
    let overColumn = columns[overColId];

    // get item indexes in each column
    const oldIndex = activeColumn.keys.indexOf(active.id);
    const newIndex = overIndex!=undefined?overIndex:overColumn.keys.indexOf(over.id);
    let newColumns;
    if(activeData.parent === overColId){ // same column so just sort
      const newColumnKeys = arrayMove(activeColumn.keys, oldIndex, newIndex);
      newColumns = {
        ...columns,
        [overColId]:{
          ...columns[overColId],
          keys:newColumnKeys
        },
      }
    } else { // different columns so remove from old, place in new col.

      //remove from old list
      activeColumn.keys.splice(oldIndex,1);
      
      // add to new list
      if(overColumn.keys){
        overColumn.keys.splice(newIndex,0,active.id);
      } else {
        overColumn.keys = [active.id];
      }
      //put together
      newColumns = {
        ...columns,
        [activeData.parent]:activeColumn,
        [overColId]:overColumn,
      }
    }
    console.log("new Columns",newColumns);
    return newColumns;

    
  }

  
  
  function handleDragEnd(event) {
    const {active, over} = event;
    if (!active|| !over|| active.id == over.id) { return;}
  
    const activeData = active.data.current;
    const overData = over.data.current;
    if(activeData.type==="column"){
      // const newColumnOrder = handleColumnDragEnd(event, activeData,overData);
      // setColumnOrder(newColumnOrder);
      onDrop(columnOrder);
    }
    else if(activeData.type ==="item"){
      // const newColumns = handleItemDragEnd(event, activeData,overData);
      // setColumns(newColumns);
      onItemDrop(columns);
      setActiveId(null);
    }
  }
}
export default MultiColumnSortableArea;