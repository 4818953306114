import { forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import Input from 'react-bootstrap/FormControl';
import {getFormattedDate} from './Helpers';
import {setGameDate,clearGuesses} from './actions/slaydle';
import {connect} from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from "react-router-dom";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const DateSelection=(props)=>{
    const navigate = useNavigate();

    const currDate = new Date();
    const formattedCurrentDate = getFormattedDate(currDate);
    
    const formattedMinDate = props.minDate;
    const minDate = new Date(props.minDate);
    const selectedDate = new Date(props.gameDate+' 12:00:00');

    const prevDate = new Date(props.gameDate+' 12:00:00');
    prevDate.setDate(prevDate.getDate()-1);
    const formattedPrevDate = getFormattedDate(prevDate);
    
    const nextDate = new Date(props.gameDate+' 12:00:00');
    nextDate.setDate(nextDate.getDate()+1);
    const formattedNextDate = getFormattedDate(nextDate);
    let completedDates=[];
    if(props.scores){
        completedDates = props.scores.map((score)=>{
            return new Date(score.gameDate+' 12:00:00');

        });
    }


    const DatepickerInput = forwardRef(({ value, onClick }, ref) => (
        <Input className="text-center" onClick={onClick} ref={ref} 
      value={value}
      readOnly
      />
    ));

    return (
        <>
            <Row>
                <Col className="button-container">
                    <LinkContainer to={props.path+formattedPrevDate}>
                        <Button 
                            disabled={formattedPrevDate<formattedMinDate}
                            onClick={()=>{
                                props.clearGuesses();
                            }}
                            
                        >&lt; {formattedPrevDate}</Button>
                    </LinkContainer>
                </Col>
                <Col className="datepicker-container"> 
                        <DatePicker 
                            todayButton="Today" 
                            dateFormat="yyyy-MM-dd" 
                            minDate={minDate}
                            maxDate={currDate}
                            selected={selectedDate} 
                            highlightDates={completedDates}
                            customInput={<DatepickerInput/>}
                            onChange={(newDate)=>{
                                navigate(props.path+getFormattedDate(newDate));
                                props.clearGuesses();
                                
                        }} />
                </Col>
                <Col className="button-container">
                    <LinkContainer to={props.path+formattedNextDate} >
                    <Button
                        disabled={formattedNextDate>formattedCurrentDate} 
                        onClick={()=>{
                            props.clearGuesses();
                        }}
                        >{formattedNextDate} &gt;</Button>
                    </LinkContainer>
                </Col>
            </Row>
        </>
    );
}


const mapStateToProps = (state) => {
    return {
      
        // tasks: state.tasks,
        // hasErrored: state.itemsHasErrored,
        scores: state.scores
    };
  };
  
  
  const mapDispatchToProps = (dispatch) => {
    return {
      setGameDate: (newDate) => dispatch(setGameDate(newDate)),  
      clearGuesses:()=>dispatch(clearGuesses()),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(DateSelection);
  