
import React,{useEffect, useState} from 'react';
import { connect } from 'react-redux';

import AddItem from 'components/ui/forms/AddItem';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Popup from 'components/ui/Popup';
import AnimateItem from 'components/ui/AnimateItem';
import { Validate } from 'helpers/Helpers';

import { 
  addCollaborator,
  removeCollaborator,
  unsubscribeObserver, 
} from './actions/tasks';

import { BiTrash } from 'react-icons/bi';
import { Button } from 'react-bootstrap';




const TaskList = ({
  tasklist, 
  tasklistId, 
  addCollaborator,
  removeCollaborator
  
})=> {
  

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);  
    };
    const handleOpen = () => {
        setShow(true);  
    };
    const title = "Collaborators";
    console.log(tasklist);
    
    const {collaborators} = tasklist;
    let keys = [];
    if(collaborators){
        keys = Object.keys(collaborators);
    }
  


  const submitAddCollaborator=(obj)=>{
    const email = obj.title;
    if(email && Validate.email(email)){

      addCollaborator(
        tasklistId,
        {
          emailAddress: obj.title,
          title:tasklist.title
        }
      );
    }
  }
  const submitRemoveCollaborator = (key,emailAddress)=>{
    removeCollaborator(
        tasklistId,{
            collaboratorId:key,
            emailAddress:emailAddress
        }
    );
  }


  return (
    <>

    <Row><Col >
        <Button onClick={handleOpen}>{title}</Button>
        <Popup title={title} show={show} handleClose={handleClose}>

            <AddItem  
                label="Collaborator Email Address"
                buttonText="Add Collaborator"
                submitFunction={(val)=>submitAddCollaborator(val)} 
            />
            {keys.map(key => (
                
                <AnimateItem key={key}>
                  <Row>

                    <Col>
                      {collaborators[key]} 
                    </Col>
                    <Col>
                      <Button
                          onClick={()=>{submitRemoveCollaborator(key,collaborators[key])}}
                          >
                        <BiTrash/>
                      </Button>
                    </Col>
                  </Row>
                </AnimateItem> 
            
            ))}
        </Popup >
      </Col></Row>
  
  </>
  );

}

TaskList.propTypes = {
  
};


const mapStateToProps = (state) => {
  return {
    tasklist:state.tasklist,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    addCollaborator: (tasklistId, userId)=>dispatch(addCollaborator(tasklistId, userId)),
    removeCollaborator: (tasklistId, userId)=>dispatch(removeCollaborator(tasklistId, userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);

