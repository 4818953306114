
import React, { useState } from 'react';

import { connect } from 'react-redux';

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Input from 'components/ui/forms/Input';
import { BiEdit, BiSave, BiX} from 'react-icons/bi';


const TitleEdit = ({title,onSave})=>  {
  
  const [editText, setEditText] = useState(title); 
  const [isEditing, setIsEditing] = useState(false); 
  

    const submitSave=(e)=>{
        setIsEditing(false);
        onSave(editText);
    }
  

  
  let titleContent;
  let editButtons;
  if (isEditing===true ) {
    titleContent = (
      <Col>
        <Input
          label="Title"
          value={editText}
          onChange={(val)=>setEditText(val)} 
          onEnter={submitSave}
          />
      </Col>);
      editButtons = (
        <>
        <Button 
          size="sm"
          onClick={
            submitSave
          }
        >
          <BiSave/> Save
        </Button>
        <Button 
          size="sm"
          onClick={(e) => {
            setIsEditing(false);
            setEditText(title);
          }}
        >
          <BiX aria-label="Cancel"/>
        </Button>
      </>
    );
  } else {
    titleContent = (
      <>
        <h1>
            {title} 
        </h1>
      </>);
    editButtons = (
        <>
            <Button 
                size="sm" 
                onClick={() => {
                    setIsEditing(true);
                }}
            >
                <BiEdit aria-label="Edit Title"/>
            </Button>
      </>
    );
  }
  return (
    <Row>
      
      {titleContent}

    <Col xs="auto">
      {editButtons}
      
    </Col>
    </Row>
  );

}




const mapStateToPropsTask = (state) => {
  return {
  };
};


const mapDispatchToPropsTask = (dispatch) => {
  return {
  };
};

export default connect(mapStateToPropsTask, mapDispatchToPropsTask)(TitleEdit);
