
import {Navbar, Nav} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import AuthWidgets from 'components/auth/AuthWidgets';

import AnimatedPage from 'components/ui/AnimatedPage';
import AnimatedOutlet from 'components/ui/AnimatedOutlet';
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {useLocation} from "react-router-dom";
import { useRef } from 'react';

export default function MainLayout() {
  
  
  

  return (
    <>
    <div id="main-container">
      <div id="header">
        <Navbar collapseOnSelect  bg="light" expand="lg">
          <Container>
            <LinkContainer to="/">
              <Navbar.Brand>ian-lee.ca</Navbar.Brand>
            </LinkContainer>
            
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <LinkContainer to="/tasks">
                  <Nav.Link>Tasks</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/slaydle">
                  <Nav.Link>Slaydle</Nav.Link>
                </LinkContainer>
                <AuthWidgets displayName logout login></AuthWidgets>
                
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    
      <div id="detail">
        <Outlet/>
          {/* <AnimatedPage type="fade">
            <AnimatedOutlet />
          </AnimatedPage>
         */}

      </div>
      <div id="footer">
      </div>
    </div>
    <ToastContainer/>
    </>
  );
}