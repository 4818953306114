import PortfolioItem from "./PortfolioItem";
import portfolioData from "../../assets/data/portfolioData";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Portfolio = (props)=>{


    return (
    <Row>
        {portfolioData.map((item)=>(
            <Col key={item.url}>
                <PortfolioItem url={item.url} title={item.title} description={item.description} skills={item.skills} img={item.img} />
            </Col>
        ))}
    </Row>);

}


export default Portfolio;