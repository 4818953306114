
import React, { useDeferredValue ,useState, useEffect} from 'react';
import { connect } from 'react-redux';
import {submitGuess,clearGuesses} from './actions/slaydle';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Input from 'react-bootstrap/FormControl';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Spinner from 'components/ui/Spinner';
/*

*/
const GuessSubmission =(props)=> {
  const [nextGuess, setNextGuess]=useState('Strike');
  const [textSearch, setTextSearch]=useState('');
  
  

  
  const numberOfGuesses = (props.gameState.score)?props.gameState.score.score:props.gameState.guessesInfo.length;
  const notGuessed = possible => ! props.guessed.some(t=>t.text === possible) ;
  const searched = possible => possible.toLowerCase().includes(textSearch) ;
  const filterList = (obj)=>{
    let newObj = obj;
    if(props.guessed.length >0){
      newObj = newObj.filter(notGuessed);
    }
    if(textSearch!==""){
      newObj = newObj.filter(searched);
    }
    return newObj;
  };
  const possibleKeys = Object.keys(props.possibleguesses);
  const deferredPossibleKeys = useDeferredValue(possibleKeys);
  const filteredList = filterList(deferredPossibleKeys);
  const firstItem = filteredList.length?filteredList[0]:'';
  useEffect(()=>{
    if(!filteredList.includes(nextGuess)){
      setNextGuess(firstItem);
    }
  },[firstItem, nextGuess,filteredList]);
  const answerUI = (
      
    <Row>
      <Col>
        <h2>
          The answer is 
          <span className="answer"> {props.gameState.answer}</span>
        </h2>
      </Col>
    </Row>
  );
  const winningUI = (
  
    <Row>
      <Col>
    You Guessed Correctly in {numberOfGuesses} Guess{numberOfGuesses>1 && 'es'}
      </Col>
    </Row>);
  const losingUI = (
  
    <Row>
      <Col>
    You Reached the Maximum Guesses of {numberOfGuesses} Guesses
      </Col>
    </Row>);
  const nextGuessUI = ( 
    <>
    <Row>
      <Col>
        <Input placeholder="Search for a card" onChange={(e)=>{setTextSearch(e.target.value.toLowerCase())}}/>
            
      </Col>
    </Row>
    <Row>
      <Col>
        <Form.Select onChange={(e)=>{setNextGuess( e.target.value)}}>
            
            {filteredList.map((possible,posIndex) => (
                <option  key={possible} value={possible}>{possible}</option>
            ))}
        </Form.Select>
        {(false)?<Spinner/>:<></>}
      </Col>
    </Row>
    <Row>
      <Col>
        <Button variant="primary" onClick={() => {
            props.submitGuess({
                guess: nextGuess,
            });
        }}>Guess</Button>

      </Col>
    </Row>
    </>
    );
  const restartUI = (
    <div>
        <button onClick={()=>{
            props.restart();
        }}>
            Restart
        </button>
    </div>
  );if(restartUI){}
  
  if(props.gameState.status ==='Won'){
    return (
      
      <>
        {answerUI}
        {winningUI}
        {/* {restartUI} */}
      </>
    );
  }
  else if(props.gameState.status ==='Lost'){
    return (
      
      <>
        {losingUI}
        {/* {restartUI} */}
      </>
    );
  } else if(props.gameState.status ==='In Progress'){
    return (
      
    <>
        {nextGuessUI}
      </>
    );
  }
  return (
      
    <></>
  );
  
}


const mapStateToProps = (state) => {
  return {
    
      // tasks: state.tasks,
      // hasErrored: state.itemsHasErrored,
      // isLoading: state.itemsIsLoading
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    submitGuess: (newItem) => dispatch(submitGuess(newItem)),  
    restart: ()=> dispatch(clearGuesses()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuessSubmission);

