import React, {useState} from 'react';
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  useDroppable,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { MdDragHandle } from 'react-icons/md';

import { useSortable } from '@dnd-kit/sortable';
import {SortableItem} from '../SortableItem';
import {CSS} from '@dnd-kit/utilities';

const SortableColumn=({onRender=null, onDrop=null, 
    title="1",
    keys=[],
    hasHandle=false,
    id,
}) =>{

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({id: id,data: {
    type: 'column',
  },});
  
  const { setNodeRef:setColNodeRef } = useDroppable({
    id:id,
    data:{type:"column-bottom"}
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    
  };
  
    return (
      <div ref={setNodeRef} className="sortable-column" style={style}  key={id} id={id}> 
          {title} 
          <div className="drag-handle" ref={setActivatorNodeRef}  {...attributes} {...listeners}><MdDragHandle/></div>

            <SortableContext 
                items={keys}
                strategy={rectSortingStrategy}
                >
                {keys.map(key => 
                <SortableItem parent={id} key={key} id={key} hasHandles={hasHandle} >
                    {onRender(key)}
                </SortableItem>)
                }
                <div ref={setColNodeRef} className="col-drop-area">
                </div>
            </SortableContext>
      </div> 
    );
  

  
}
export default SortableColumn;