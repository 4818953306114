
import React from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';

const Popup = ( {title, children,show, handleClose}) => {

  

  
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
          <Modal.Body>
            {children}
          </Modal.Body>
      </Modal>
        
    </>
  );
  
}



const mapStateToProps = (state) => {
  return {
    
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Popup);

