import React, { useEffect} from 'react'
import { useDispatch } from 'react-redux'

// slaydle specific content/data
import possibleguesses from './Possible.js';
import {categoryNames, title, path,maxGuesses,gameSlug} from './slaydleData.js';
import SlaydleInfoContent from './SlaydleInfoContent';


import Guesses from './Guesses';
import GuessSubmission from './GuessSubmission';
import DateSelection from './DateSelection.js';
import {getCurrentDate} from './Helpers';
import InfoPopup from '../ui/InfoPopup';

import Share from '../ui/Share';

import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {submitScore,getScores} from './actions/slaydle';
import {generateAnswer, validateDate, generateGuessInfo} from './Helpers';

// import Adsense from '../adsense/adsense';


const SlaydleApp = (props) =>  {
  
  const dispatch = useDispatch();
  
  const defaultGameDate = getCurrentDate('-');
  let { gameDate = defaultGameDate } = useParams();
  console.log(gameDate);
  if(!validateDate(gameDate)){
    //invalid date.
    throw new Error('Invalid Date'); 
  }
  const minDate = '2023-05-20';
  const currDate = getCurrentDate();
  
  if(gameDate<minDate ){
    //redirect outside available date range.
    // navigate(path+'/'+minDate);
    throw new Error('Date Unavailable');
  } else if (gameDate > currDate){
    //redirect outside available date range.
    // navigate(path+'/'+currDate);
    throw new Error('Date Unavailable');
  }

  const correctAnswerTemp = generateAnswer(gameDate, possibleguesses);
  let gameState = {
    status:'In Progress',
    guesses:[],
    guessesInfo:[],
    answer:correctAnswerTemp};
  
  
  
  
  let score = undefined;
  if(props.scores!==false){
    score = props.scores.find(element=>element.gameDate === gameDate);
    if(score){
      gameState.score = score;
      gameState.status = 'Won';
      gameState.guesses = score.guesses;
      
    } else {
      gameState.guesses = props.guesses;
    }
  } else {
    gameState.guesses = props.guesses;
  }
  
  for(var i = 0; i< gameState.guesses.length; i++){
    gameState.guessesInfo.push(generateGuessInfo(gameState.guesses[i], correctAnswerTemp,possibleguesses,categoryNames));
  }

  if(score===undefined&&gameState.guessesInfo.length>0 && gameState.guessesInfo[gameState.guessesInfo.length-1].correct === true){
    gameState.status = 'Won';
    dispatch(submitScore(gameSlug,props.guesses.length, gameDate, gameState.guesses));
  } else if(score===undefined && gameState.guessesInfo.length >= maxGuesses){
    gameState.status = 'Lost';
  } 
  

  useEffect(() => {
    if(props.scores===false){
      dispatch(getScores(gameSlug));
    }
    return; 
  },[props.scores,dispatch,props.user]);
  

  
  return (
    <Container className={gameSlug}>
      <Row><Col><h1>{title}</h1><InfoPopup><SlaydleInfoContent/></InfoPopup></Col></Row>
      
      <DateSelection gameDate={gameDate} path={path} minDate={minDate}></DateSelection>
      {/* previous guesses */}
      <Guesses gameDate={gameDate} guesses={gameState.guessesInfo} categoryNames={categoryNames}></Guesses>
      {/* UI for new guess */}
      <GuessSubmission gameState={gameState} guessed={gameState.guessesInfo} possibleguesses={possibleguesses}></GuessSubmission>
      {/* UI for sharing */}
      <Row><Col><Share title={title+" - " + gameDate } text={"Try this game! "} hashtags={["slaythespire","slaydle","wordle"]}/></Col></Row>
      {/* ads? */}
      {/* <Adsense></Adsense> */}
    </Container>
  );
}


const mapStateToProps = (state) => {
  return {
      guesses: state.guesses,
      scores:state.scores,
      user:state.user,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    getScores: (game)=>{dispatch(getScores(game))},
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SlaydleApp);

