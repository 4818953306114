
import {getFunctions, httpsCallable} from 'firebase/functions';

export function submitScore(game, score, gameDate, guesses){
    console.log('submitting score');
    return (dispatch)=>{
        const params = {
            game:game,
            score:score,
            gameDate:gameDate,
            guesses:guesses
        };

        
        const recordQuizScore = httpsCallable(getFunctions(), 'recordQuizScore');
        recordQuizScore(params)
        .then(response => {
            if(response.data.error){
                return;
            }
            const scores = response.data.scores;
            
            dispatch(setScores(scores));
            
        });
    };
    
}
export function getScores(game){
    console.log('fetching scores...');
    return (dispatch)=>{
        const params = {
            game:game,
        };
        const getQuizScore = httpsCallable(getFunctions(), 'getQuizScore');
        getQuizScore(params)
        .then(response => {
            if(response.data.error){
                return;
            }
            const scores = response.data.scores;
            dispatch(setScores(scores));
        });  
    }
}
export function setScores(scores){
    return {
        type:'SETGAMESCORES',
        scores:scores
    }
}
export function setGuesses(guesses){
    console.log('SetGuesses',guesses);
    return {
        type:'SETGUESSES',
        guesses
    }
}

export function submitGuess(guess){
    console.log('guess',guess);
    
    return {
        type: 'GUESS',
        guess
    }
}
export function clearGuesses(){
    return {
        type: "CLEARGUESSES"
    }
}
export function setGameDate(newDate){
    
    return {
        type:'SETGAMEDATE',
        newDate
    }
}