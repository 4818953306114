import { getAuth, signInAnonymously } from "firebase/auth";
let processing = false;
export default function signInAnon(){
    if(processing === true){return;}
    processing =true
    const auth = getAuth();
    signInAnonymously(auth)
    .then(() => {
        // Signed in..
        console.log('Anon Signed in..');
        processing= false;
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("Anon Signin Error: "+errorCode + ": "+ errorMessage);
        // ...
    });
}