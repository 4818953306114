
const portfolioData = [
    {
        url:"/slaydle",
        title:"Slaydle",
        description:"Wordle clone for the game Slay the Spire.",
        skills:[
            "WIP",
            "React",
        ],
        img:"",
    },
    
    {
        url:"/tasks",
        title:"Task",
        description:"Basic Task management system",
        skills:[
            "WIP",
            "React",
        ],
        img:"",
    },
    
    {
        url:"https://www.hofvan.com",
        title:"Hofvan Website",
        description:"Real Estate ",
        skills:[
            "PHP",
            "JS",
            "Mapbox",
            "Algolia"
        ],
        img:"",
    },
    


];
export default portfolioData;