export default function Privacy(props){

    const title = "Privacy Policy";
    const welcomeMessage = "This is a development website so not much thought has gone into this page.  ";
    const content = "This site uses a bunch of Google services including: authentication, logging, analytics.  See Google's privacy settings for more information.";

    return (
        <div>
            <h2>{title}</h2>
            <div>{welcomeMessage}</div>
            <div>{content}</div>
        </div>
    );
}