
import TaskList from './TaskList.js';


import { useParams } from 'react-router-dom';
import {connect} from 'react-redux';
import './tasks.scss';

import {
  
  newTaskList, 
  
} from './actions/tasks';


const TasksApp=()=> {
  
  const {tasklistId = undefined} = useParams();
  const {taskId = undefined} = useParams();

  
  
    
  
  return (
    <>
    
    
    
      <TaskList 
        tasklistId={tasklistId} 
        taskId={taskId}/>
    
    </>
  );
}



const mapStateToProps = (state) => {
  return {
      
      personalTasklists: state.personalTasklists,
      user:state.user,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    
  
    myNewTaskList: (uid,title)=>dispatch(newTaskList(uid,title)),
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksApp);

