
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from 'redux';

import {user} from 'components/auth/reducers';

import {slaydleReducer, slaydleInitialState} from 'components/slaydle/reducers';

import {tasksReducer, tasksInitialState} from 'components/tasks/reducers';

// combine reducers and initial states.

function configureStore(initialState) {
    
    let rootReducer = combineReducers({
        ...slaydleReducer,
        ...tasksReducer,
        user:user
    });

  return createStore(
      rootReducer,
      initialState,
      applyMiddleware(thunk)
  );
}


const combinedInitialState = {...slaydleInitialState,user:false, ...tasksInitialState};

const store = configureStore(combinedInitialState);

export function AppProvider({ children }) {
  return (
    <Provider store={store}>
     
        {children}
     
    </Provider>
  );
}
