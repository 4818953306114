
import React,{useEffect} from 'react';
import { connect } from 'react-redux';

import Task from './Task';
import TaskDetails from './TaskDetails';
import AddTask from './AddTask.js';
import Container from 'react-bootstrap/Container';
import Collaborators from './Collaborators';

import TitleEdit from 'components/ui/forms/TitleEdit';
import Switch from 'components/ui/forms/Switch';

import SortableArea from 'components/ui/dnd/SortableArea';
import MultiColumnSortableArea from 'components/ui/dnd/boardview/MultiColumnSortableArea';
import { Row,Col } from 'react-bootstrap';

import { 
  initiateTasklist,
  unsubscribeObserver,
  updateTaskList,
  updateTaskListOrder, 
  updateBoardOrder,
  updateBoardItemOrder,
  addBoardColumn,
} from './actions/tasks';
import TaskCard from './boardview/TaskCard';


/**
 * tasklist
 *  key
 *  title
 *  tasks
 *    taskid
 *      title
 *      done
 *      created
 *      lastModified
 *      description
 */

var oldtasklistId;
const TaskList = ({
  tasklist, 
  tasklistId, 
  taskId,
  myInitiateTasklist ,
  updateTaskList,
  updateTaskListOrder,
  updateBoardOrder,
  updateBoardItemOrder,
  addBoardColumn,
})=> {


  useEffect(()=>{
    if(tasklistId !==oldtasklistId){
      if(oldtasklistId !==undefined){
        unsubscribeObserver('tasklist/'+oldtasklistId);
      }
      myInitiateTasklist(tasklistId);
      oldtasklistId = tasklistId;
    }
    
    return ()=>{

      unsubscribeObserver('tasklist/'+tasklistId); 
      oldtasklistId = undefined;
    }
  },[myInitiateTasklist,tasklistId]);

  
  
  if(tasklistId !== oldtasklistId){
    return (null);
  }
  if(!tasklist){return null}



  const {title,tasks,customOrder,boardview} = tasklist;
  
  //Use custom Order, and append any unordered tasks to end.
  let keysSet = [];
  if(customOrder){
    keysSet = [...customOrder];
  } 
  if(tasks){
    keysSet = [...keysSet,...Object.keys(tasks)];
  }
  let keys = [... new Set(keysSet)];


  let taskDetails=null;
  if(taskId && tasks && tasks[taskId]){
    taskDetails = <TaskDetails task={tasks[taskId]}/>;
  }

  const onDrop = (taskIds)=>{
    updateTaskListOrder(tasklistId, taskIds);
  }

  const onRender = (key)=>{
    return (<Task key={key} taskId={key} task={tasks[key]} />);
  }


  const onBoardDrop = (taskIds)=>{
    updateBoardOrder(tasklistId, taskIds);
  }

  const onBoardItemDrop = (taskIds)=>{
    updateBoardItemOrder(tasklistId, taskIds);
  }

  const onBoardRender = (key)=>{
    return (<TaskCard key={key} taskId={key} task={tasks[key]} />);
  }
  const onNewBoardColumn = ()=>{
    addBoardColumn(tasklistId);
  }

  let TasklistArea;
  if(boardview && boardview.active===true){
    const {
      columns={
        "col1":{title:"col1",keys:keys},
        "col2":{title:"col2",keys:[]},
      },
      columnOrder=["col1","col2"],
    } = boardview;

    TasklistArea = 
    <div className="tasklist-area multicolumn">
      <MultiColumnSortableArea onRender={onBoardRender} onDrop={onBoardDrop} onItemDrop={onBoardItemDrop} onNewBoardColumn={onNewBoardColumn} propColumns={columns} propColumnOrder={columnOrder} hasHandle={false}/>
    </div>;
      
  } else {
    TasklistArea = 
    <div className="tasklist-area">
      <SortableArea onRender={onRender} onDrop={onDrop} keys={keys} hasHandle={true}/>
    </div>;
  }
  
  return (
    <>

    <Container fluid>
      <TitleEdit title={title} onSave={(val)=>{
        updateTaskList(tasklist,tasklistId,{title:val});
      }}/>
      <Switch field="boardview" label="Boardview" 
      value={boardview?boardview.active:false}
      onChange={(val)=>{
        console.log(val);
        updateTaskList(tasklist, tasklistId, {boardview:{...boardview,active:val}});
      }}/>
      <Collaborators tasklistId={tasklistId}/>
      <AddTask tasklistId={tasklistId}/>

        {TasklistArea}

    </Container>
  
  {taskDetails?taskDetails:<></>}
  </>
  );

}

TaskList.propTypes = {
  
};


const mapStateToProps = (state) => {
  return {
    tasklist:state.tasklist,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    myInitiateTasklist: (tasklistId)=>dispatch(initiateTasklist(tasklistId)),
    updateTaskList:(tasklist,tasklistId,updateObj)=>dispatch(updateTaskList(tasklist,tasklistId,updateObj)),
    updateTaskListOrder: (tasklistId,newList)=>dispatch(updateTaskListOrder(tasklistId,newList)),
    updateBoardOrder:  (tasklistId,newList)=>dispatch(updateBoardOrder(tasklistId,newList)),
    updateBoardItemOrder:  (tasklistId,newList)=>dispatch(updateBoardItemOrder(tasklistId,newList)),
    addBoardColumn: (tasklistId)=>dispatch(addBoardColumn(tasklistId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskList);

