
import React, { useState } from 'react';
import { connect } from 'react-redux';
import {deletePTask,updatePTask} from '../actions/tasks';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';

import Input from 'components/ui/forms/Input';


import { Card } from 'react-bootstrap';

const TaskCard = ({task,deleteTask,updateTask})=>  {
  
  
  const [editNumber, setEditNumber] = useState(task.number); 
  
  const navigate = useNavigate();
  let numberContent = null;
  if(task.type==="number"){
    numberContent = (
      <div>
        <Input 
        hasLabel={false}
        type="number"
        value={editNumber}
        onChange={(val)=>setEditNumber(val)}
        onBlur={(val)=>{
          updateTask(
            task,
            {
              number: editNumber
            });
          }}
          
          
          />
      </div>
    );
  }


  
  let taskContent;

  
    taskContent = (
        <>
        <Card.Title>

            <input
                type="checkbox"
                checked={task.done}
                onChange={e => {
                    updateTask(task,{
                        done: e.target.checked
                    });
                }}
            />
            {numberContent}
            {task.title}
        
        </Card.Title>
        {task.dueDate&&<Card.Text>{task.dueDate}</Card.Text>}

        </>);
    

  return (
    <>

        <Card className="task-card" style={{"width":"200px"}}>
            <Card.Body>
            
                {taskContent}
            </Card.Body>
        </Card>
    </>
  );

}



TaskCard.propTypes = {
  deleteTask: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired
};


const mapStateToPropsTask = (state) => {
  return {
  };
};


const mapDispatchToPropsTask = (dispatch) => {
  return {
    deleteTask: (task) => dispatch(deletePTask(task)),
    updateTask: (task, newTask) => dispatch(updatePTask(task, newTask)),


  };
};

export default connect(mapStateToPropsTask, mapDispatchToPropsTask)(TaskCard);
