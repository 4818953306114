import { task, tasklist, personalTasklists ,collaboratorTasklists} from './tasks';

const tasksReducer = {
    
    task,
    tasklist,
    personalTasklists,
    collaboratorTasklists
};
const tasksInitialState = {task:[], tasklist:{},personalTasklists:{},collaboratorTasklists:{}};
export {tasksReducer, tasksInitialState};