import md5 from 'js-md5';

export function getCurrentDate(separator='-'){

    let newDate = new Date();
    return getFormattedDate(newDate,separator);
}
export function getFormattedDate(newDate, separator='-'){
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`;
}



export const generateAnswer = (mydate, possibleGuesses) =>{
  
    const possibleAnswers = Object.keys(possibleGuesses);
    const arrSize = possibleAnswers.length;
    const hash =parseInt( md5(mydate).substr(0,8), 16);
    const randomNumber = hash%arrSize;
    const answer = possibleAnswers[randomNumber];
    return answer;
  }
    
export const generateGuessInfo = (guess, correctAnswer, possibleGuesses, categoryNames)=> {
    const currentInfo =  possibleGuesses[guess];
    const correctInfo =  possibleGuesses[correctAnswer];
    
    let guessInfo = {
      text:guess,
      cats:[],
      correct: guess===correctAnswer
    }
    let i = 0;
    for(i = 0; i < categoryNames.length; i++){
      const catName = categoryNames[i].name;
      const type = categoryNames[i].type;
      const currentValue = currentInfo.cats[catName];
      const correctValue = correctInfo.cats[catName];
      let catNear =  type==="numeric"?(currentValue<correctValue?">":(currentValue>correctValue?"<":false)):false;

      let category = {
        text:catName,
        type:type,
        value: currentValue,
        correct:currentValue === correctValue,
        near: catNear, 
      };
      guessInfo.cats.push(category);
    }
    return guessInfo;
  };
export const validateDate = (myDate)=>{
    const re = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|30|31)$/;
    const ok = re.exec(myDate);
    return ok;
}