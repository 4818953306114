import Form from 'react-bootstrap/Form';

const Switch = (props)=>{
    const {
        field,
        label="",
        value="",
        onChange=()=>{},
        onBlur=()=>{},
        type="switch",
        
    } = props;

    const input = (
        
        <Form.Check // prettier-ignore
            id={"checkbox-"+field}
            type={type}
            label={label}
            checked={value}
            onChange={(e)=>onChange(e.target.checked)} 
            
            onBlur={(e)=>onBlur(e.target.checked)} 
        />
    );

    

    return (<>
        {input}
    </>);
}

export default Switch;