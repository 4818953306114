

import React from 'react';
import { connect } from 'react-redux';

import Table from 'react-bootstrap/Table';


const SlaydleInfoContent = ({children}) => {

  return (
    <>
      
      <p>Search for/Select a card and guess to find the daily card.  
        Each guess will reveal information on qualities for the guessed card that match with the daily card.</p>
      <Table>
        <thead>
          <tr><th>Column Name</th><th>Column Explanation</th></tr>
        </thead>
        <tbody>
        <tr><td>Card Name</td><td>Self Explanitory - Name of the Card guessed</td></tr>
        <tr><td>Cost</td><td>Energy cost of the card when not upgraded.</td></tr>
        <tr><td>Type</td><td>Card type - can be: Attack, Skill, Power</td></tr>
        <tr><td>Value</td><td>First number in non-upgraded card text. Defaults to 0 if no number present.</td></tr>
        <tr><td>Rarity</td><td>Rarity of the card - can be Basic, Common, Uncommon, Rare, Special</td></tr>
        <tr><td>Class</td><td>Colorless, Ironclad, Silent, Defect, Watcher</td></tr>
        <tr><td>Exhaust</td><td>Self Exhaust - Whether or not the card exhausts itself as an effect.  Cards that exhaust other cards but not itself are not counted. Ethereal is not counted</td></tr>
        </tbody>
      </Table>
        
      
    </>
  );
  
}



const mapStateToProps = (state) => {
  return {
    
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SlaydleInfoContent);

