
const firebaseConfig = {
    apiKey: "AIzaSyBcnWtorrFDMgjpPULguUCSfn-1iwrgslQ",
    authDomain: "react-tasks-e4aa9.firebaseapp.com",
    databaseURL: "https://react-tasks-e4aa9-default-rtdb.firebaseio.com",
    projectId: "react-tasks-e4aa9",
    storageBucket: "react-tasks-e4aa9.appspot.com",
    messagingSenderId: "978208426915",
    appId: "1:978208426915:web:87909f5e8e0c5e62fbe816",
    measurementId: "G-25HJKP3LSJ"
  };
export default firebaseConfig;