

export function task(state = [], action) {
    switch (action.type) {
        case 'TASK_FETCH_DATA_SUCCESS':
            return action.task;

        default:
            return state;
    }
}

export function tasklist(state = [], action){
    switch (action.type) {
        case 'TASKLIST_FETCH_DATA_SUCCESS':
            return action.tasklist;
        default:
            return state;

    }
}

export function personalTasklists(state = [], action){
    switch (action.type) {
        case 'PERSONAL_TASKLISTS_FETCH_DATA_SUCCESS':
            return action.tasklists;
        default:
            return state;

    }
}

export function collaboratorTasklists(state = [], action){
    switch (action.type) {
        case 'COLLABORATOR_TASKLISTS_FETCH_DATA_SUCCESS':
            return action.tasklists;
        default:
            return state;

    }
}