import React, {forwardRef} from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {MdDragHandle} from 'react-icons/md';

// export function SortableItem(props) {
const SortableItem = forwardRef(({id, ...props}, ref)=> {
  const {hasHandles, parent} = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: id,data: {
    type: 'item',
    parent: parent,
  },});

  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  if(hasHandles){
    return (
      <div ref={setNodeRef} style={style} className="has-drag-handle" >
        {props.children}
        <div className="drag-handle" {...attributes} {...listeners}><MdDragHandle/></div>
      </div>
    );
  }
  
  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} >
      {props.children}
    </div>
  );
});

export {SortableItem};