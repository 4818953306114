
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Guess from './Guess';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Guesses extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    
  }

  render(){
    
  return (
    
    <Row>
      <Col>
      <Table>
        <thead>
          <tr>
            <th>Card Name</th>
            {this.props.categoryNames.map(categoryName => (
              <th key={categoryName.name}>{categoryName.name}</th>
            ))}
          </tr>
        </thead>
        
        <tbody>
        {this.props.guesses.length?
          this.props.guesses.map(guess => (
              <Guess guess={guess}  key={guess.text}/>
          )):(<tr><td></td>{this.props.categoryNames.map(categoryName => (
            <td key={categoryName.name}></td>
          ))}</tr>)}
        </tbody>
      </Table>
    </Col>
    </Row>
  );
  }
}



const mapStateToProps = (state) => {
  return {
    
      // tasks: state.tasks,
      // hasErrored: state.itemsHasErrored,
      // isLoading: state.itemsIsLoading
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Guesses);

