
import AuthWidgets from '../components/auth/AuthWidgets';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
function Reset() {
  
  
  return (
    <Container className="resetpassword">
    
    <Row>
      <Col>
        <h1>Reset Password</h1>
      </Col>
    </Row>
    <Row className="resetpassword__container">
      <Col>
        <AuthWidgets resetPassword/>
      </Col>
    </Row>
    <Row>
      <Col>
        <AuthWidgets loginLink/>
        <AuthWidgets registerLink/>
      </Col>
    </Row>
    </Container>
  );
}
export default Reset;