
const animations = {

    fade: {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 }
    },
    fadeup: {
        initial: { opacity: 0, y:10},
        animate: { opacity: 1, y:0 },
        exit: { opacity: 0 }
        
    },
    
    slide: {
        initial: { opacity: 0,x:-100 },
        animate: { opacity: 1,x:0 },
        exit: { opacity: 0,x:100 }
    }
};
export default animations;