import React, { useState } from 'react';
import { connect } from 'react-redux';
import {deletePTask,updatePTask} from './actions/tasks';

import Popup from 'components/ui/Popup';
import {useNavigate} from 'react-router-dom';


import Button from 'react-bootstrap/Button';

// import Form from 'react-bootstrap/Form';


import Input from 'components/ui/forms/Input';
import {StdDatePicker} from 'components/ui/DatePicker';
import Select from 'components/ui/forms/Select';

const TaskDetails = (props)=>  {
  const {task, updateTask} = props;
  
  const navigate = useNavigate();
  
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false);
    navigate("..", { relative: "path" });
  };
  // const handleShow = () => setShow(true);
  
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({...task}); 
  const {
    title,
    description,
    dueDate,
    type="text",
    number,
  } = form;

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });
    if ( !!errors[field] ) {
      setErrors({
        ...errors,
        [field]: null
      });
    }
    
  };
  const types = [
    { 
      val:"text",
      title:"Text"
    },
    {
      val:"number",
      title:"Number"
    },
  ];
  let numberContent = null;
  if(type ==="number"){
    numberContent = (
      <>
      <Input
        field={"number"}
        label={"Number"}
        value={number}
        onChange={(val)=>setField("number",val)}
        type="number"
      />
      </>
    );
  }


  const submitForm = ()=>{
    updateTask(task,{
      ...form
    });
    handleClose();
  }
  

    let taskContent;
/* title
 * done
 * created
 * lastModified
 * description
 * */
    taskContent = (
      <>
        <Input 
          field={"title"}
          label={"Title"}
          value={title}
          onChange={(val)=>setField("title",val)}
        />
        <Select
        
          field={"type"}
          label={"Type"}
          value={type}
          onChange={(val)=>setField("type",val)}
          options={types}
        />
        {numberContent}
        <Input 
          field={"description"}
          label={"Description"}
          value={description}
          onChange={(val)=>setField("description",val)}
        />
        <StdDatePicker 
          field={"dueDate"}
          label={"Due Date"}
          value={dueDate}
          onChange={(val)=>setField("dueDate",val)}
        />
       

        <Button 
          onClick={(e) => {
            submitForm();
          }}
        >
          Save
        </Button>
      </>
    );
    
    return (
      <Popup title={title} show={show} handleClose={handleClose}>
          {taskContent}
      </Popup>
    );

}




const mapStateToPropsTask = (state) => {
  return {

  };
};


const mapDispatchToPropsTask = (dispatch) => {
  return {
    deleteTask: (id) => dispatch(deletePTask(id)),
    updateTask: (id, task) => dispatch(updatePTask(id, task))

  };
};

export default connect(mapStateToPropsTask, mapDispatchToPropsTask)(TaskDetails);
