

import React, { useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import {auth} from "components/auth/firebase";
import {useAuthState } from "react-firebase-hooks/auth";
import GoogleSignin from "../components/auth/GoogleSignin";
import EmailSignin from "../components/auth/EmailSignin";
import AuthWidgets from '../components/auth/AuthWidgets';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

function Login() {
  
  const [user, loading, error] = useAuthState(auth);
  if(error){};
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user&&!user.isAnonymous) navigate("/");
  }, [user, loading, navigate]);
  return (
    <Container className="login">
      <Row>
        <Col>
          <h1>Login</h1>
          <div>Signin to save your progress in Slaydle.</div>
        </Col>
      </Row>
      <Row className="login__container">
        <Col>
          <EmailSignin/>
        </Col>
      </Row>
      <Row>
        <Col>
          <GoogleSignin />
        </Col>
      </Row>
      <Row>
        <Col>
          <AuthWidgets resetPasswordLink />
          <AuthWidgets registerLink/>
          
        </Col>
      </Row>
    </Container>
  );
}
export default Login;