
import AuthWidgets from '../components/auth/AuthWidgets';
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import React, { useEffect } from "react";
import {  useNavigate } from "react-router-dom";
import {auth} from "components/auth/firebase";
import {useAuthState } from "react-firebase-hooks/auth";


function Register() {
  
  const [user, loading, error] = useAuthState(auth);
  if(error){};
  const navigate = useNavigate();
  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user&&!user.isAnonymous) navigate("/");
  }, [user, loading, navigate]);

  return (
    <Container className="register">
    <Row>
      <Col>
        <h1>Register</h1>
      </Col>
    </Row>
    <Row className="register__container">
      <Col>
        <AuthWidgets emailRegister/>
      </Col>
    </Row>
    <Row>
      <Col>
        <AuthWidgets googleSignin />
      </Col>
    </Row>
    <Row>
      <Col>
        <AuthWidgets loginLink/>
      </Col>
    </Row>
    </Container>
  );
}
export default Register;