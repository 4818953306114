
import React, { Component} from 'react';
import { connect } from 'react-redux';
import AnimateItem from 'components/ui/AnimateItem';
/*
guess
    id
    correct
    text
*/
class Guess extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    
  }

  render(){
  return (
    
    <AnimateItem tag="tr" >
        <td className="guessed">{this.props.guess.text}</td>
        {this.props.guess.cats.map(guessprop => (
            <td key={guessprop.value} className={guessprop.correct===true?"correct":"incorrect"}>
                
                  <div>
                  {guessprop.value===true ?guessprop.text:''}
                  { guessprop.value===false?"No "+guessprop.text:''}
                </div>
                <div>{guessprop.near==="<"?"<":""}{guessprop.value}{guessprop.near===">"?"<":""}</div>
                
            </td>
        ))}
      
    </AnimateItem>
  );
  }
}



const mapStateToProps = (state) => {
  return {
    
      // tasks: state.tasks,
      // hasErrored: state.itemsHasErrored,
      // isLoading: state.itemsIsLoading
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Guess);

