
import { guess,gameScores} from './slaydle';

const slaydleReducer = {
    
    guesses:guess,
    scores:gameScores
};

 const slaydleInitialState = {
    guesses:[],
    scores:false
}

export {slaydleReducer, slaydleInitialState};