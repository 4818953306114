import Portfolio from "../components/portfolio/Portfolio";
import Container from "react-bootstrap/Container";
export default function Home(props){

    const title = "Welcome";
    const welcomeMessage = "This is my site to showcase my React projects as I make them.";
    const content = "";

    return (
        <Container>
            <h2>{title}</h2>
            <div>{welcomeMessage}</div>
            <div>{content}</div>
            <Portfolio/>
        </Container>
    );
}