import React from 'react';
import ReactDOM from 'react-dom/client';
import 'assets/css/index.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';

import Router from 'app/Router';
import {AppProvider} from 'app/AppContext';
import Auth from 'components/auth/auth';


import 'react-toastify/dist/ReactToastify.css';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AppProvider>
      <Auth></Auth>
      <Router/>
      
    </AppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
