
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { FormSelect } from 'react-bootstrap';

const Select = (props)=>{
    const {
        field,
        label,
        value="",
        onChange,
        options=[]
    } = props;
    return (
        <FloatingLabel
            controlId={"floating-"+field}
            label={label}
            className="mb-3"
        >

        <FormSelect value={value} onChange={(e)=>onChange(e.target.value)} >
            
            {options.map(({val,title}) => (
                <option  key={val} value={val}>{title}</option>
            ))}
        </FormSelect>

    
    </FloatingLabel>
  );
}

export default Select;