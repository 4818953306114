import React, {useState} from 'react';
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import {SortableItem} from './SortableItem';

const SortableArea=({onRender, onDrop, keys=[], hasHandle=false}) =>{

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext 
        items={keys}
        strategy={verticalListSortingStrategy}
      >
        {keys.map(id => 
          <SortableItem key={id} id={id} hasHandles={hasHandle} >
            {onRender(id)}
          </SortableItem>)
        }
      </SortableContext>
    </DndContext>
  );
  
  function handleDragEnd(event) {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      
        const oldIndex = keys.indexOf(active.id);
        const newIndex = keys.indexOf(over.id);
        const newOrderKeys = arrayMove(keys, oldIndex, newIndex);
        onDrop(newOrderKeys);
    }
  }
}
export default SortableArea;