import Card from 'react-bootstrap/Card';


import {LinkWrapper} from 'helpers/Helpers';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

const PortfolioItem = (props)=>{
    const url = props.url;
    const title = props.title;
    const description = props.description;
    const skills = props.skills;
    const img = props.image;
    

    const myCard = (
        <Card style={{ width: '18rem' }}>
            <Card.Img variant="top" src={img} />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Stack direction="horizontal" gap={2}>
                    {skills.map((skill)=>(
                        <Badge key={skill} pill bg="primary">{skill}</Badge>
                        ))}
                </Stack>
                <Card.Text>
                {description}
                </Card.Text>
            </Card.Body>
        
        </Card>
    );
    return (<LinkWrapper to={url}>{myCard}</LinkWrapper>);
    
}

export default PortfolioItem;
