
import React from 'react';
import { connect } from 'react-redux';
import {newTaskList} from './actions/tasks';

import AddItem from 'components/ui/forms/AddItem';
import { useNavigate } from 'react-router-dom';


const AddTasklist = (props)=> {


  const uid = props.user.uid;
  const addTasklist = props.addTasklist;
  const {path} = props;
  const navigate = useNavigate();
  
  const navigateToNewTasklist = (tasklistObj)=>{
    navigate(path + tasklistObj.tasklistId);
  }
  const submitAddTask=(obj)=>{
    if(uid ){
        let newTasklistId = addTasklist(uid,obj.title, navigateToNewTasklist);
        
    }
  }
    
  return (
      <AddItem 
        md={12}
        label="Tasklist Title"
        buttonText="Add New Tasklist"
        submitFunction={(val)=>submitAddTask(val)} />
  );
  
}



const mapStateToProps = (state) => {
  return {
    user:state.user,
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    addTasklist: (uid,newItem, callback) => dispatch(newTaskList(uid,newItem, callback)),  
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTasklist);

