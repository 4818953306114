
export function guess(state = [], action){
    switch (action.type){
        case 'GUESS':
            if(action.guess.guess===""){return state;}
            return [...state,action.guess.guess];
        case 'CLEARGUESSES':
            return [];
        case 'SETGUESSES':
            return [action.guesses]
        default:
            return state;
    }
}
export function gameDate(state=[], action){
    switch (action.type){
        case 'SETGAMEDATE':
            return action.newDate;
        default:
            return state;
    }
}
export function gameScores(state=[],action){
    switch (action.type){
        case 'SETGAMESCORES':
            return action.scores;
        default:
            return state;

    }
}
