import { logInWithEmailAndPassword } from "./firebase";
import React, { useState } from "react";

import Button from 'react-bootstrap/Button';
import Input from 'react-bootstrap/FormControl';
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {Validate} from "helpers/Helpers";

const EmailSignin = ()=>{
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState("");

    
    const findFormErrors = () => {
        
        const newErrors = {}
        // email errors
        if ( !email || email === '' ) newErrors.email = 'Email cannot be blank!'
        else if ( email.length > 30 ){ newErrors.email = 'Email is too long!'}
        else if (!Validate.email(email) ){ newErrors.email = 'Invalid Email'}
        // password errors
        if ( !password || password === '' ){ newErrors.password = 'cannot be blank!'}
        
        
        return newErrors;
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        
        if(validate()){
          // No errors! Put any logic here for the form submission!
          login();
        }
        
      };
    const validate = ()=>{
      const newErrors = findFormErrors();
      setErrors(newErrors);
      if ( Object.keys(newErrors).length > 0 ) {
        // We got errors!
        return false;
      } else {
        // No errors!
        return true;
      }
    }
    const login = async ()=>{
        const error = await logInWithEmailAndPassword(email, password);
        if(error){
            setErrors(
            {
                submitError: error
            });
            console.log('login error.',error);
            toast(error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
        } else {    
            
            navigate("/");
        }
    }

    return (
    <div className="narrow">
    <Form noValidate onSubmit={handleSubmit}>
    <Row >
        <Col >
            <Form.Group>
                <FloatingLabel
                    controlId="floatingEmail"
                    label="Email address"
                    className="mb-3"
                >
                
                    <Input
                        type="email"
                        className="login__textBox"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="E-mail Address"
                        required
                        isInvalid={ !!errors.email }
                    />
                </FloatingLabel>
                <Form.Control.Feedback type="invalid">
                    {errors.email}
                </Form.Control.Feedback>
            </Form.Group>
        </Col>
        <Col >
            <Form.Group>
                <FloatingLabel
                    controlId="floatingPassword"
                    label="Password"
                    className="mb-3"
                >
            
                    <Input
                        type="password"
                        className="login__textBox"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onBlur={(e)=>validate()}
                        placeholder="Password"
                        required
                        isInvalid={ !!errors.password }
                    />
                </FloatingLabel>
                
                <Form.Control.Feedback type="invalid">
                    {errors.password}
                </Form.Control.Feedback>
            </Form.Group>
        </Col>
        <Col xs={2}>
            <Button
                type="submit"
                className="login__btn"
                // onClick={() => login()}
            >
                Login
            </Button>
        
        </Col>
    </Row>
    </Form>
    </div>
    );

}
export default EmailSignin;