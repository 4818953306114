
import React, { useState } from 'react';
import { connect } from 'react-redux';

import Input from 'components/ui/forms/Input';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const AddItem = (props)=> {
    
    const {
        buttonText = "Add New",
        label = "Title",
        submitFunction ,
        md=12
    } = props;
const [addText,setAddText] = useState('');
  const resetInput =  ()=>{
    setAddText('');
  }
  const onInputchange = (value)=> {
    setAddText( value)
  }
  const onEnterDown = ()=>{
    
    onSubmit();
    
  }
    const onSubmit=(e)=>{
        submitFunction(
            {
                title: addText,
            }
        );
        resetInput(); 
    }
  

  

    
  return (
    <Row>

      <Col md={md}>
        <Input
          label={label}
          value={addText}
          onChange={value=>onInputchange(value)}
          onEnter={()=>onEnterDown()}
          />
      </Col>
      <Col>
        <Button onClick={(e) => {
            
            onSubmit();
          
          }}>{buttonText}</Button>
    </Col>
    </Row>
  );
  
}



const mapStateToProps = (state) => {
  return {
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddItem);

